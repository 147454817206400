import {

  BrowserRouter as Router,
  Routes,
  Route,
  Navigate
} from "react-router-dom";

//import HomePage from "./HomePage";
import HomeOperatore from "./Operatore/HomeOperatore"

import OperatorePages from "./Operatore/OperatorePages";

//import Chiuse from "./Operatore/PraticheBox/Chiuse";

//import Attive from "./Operatore/PraticheBox/Attive/Attive";

import SignIn from "./Component/Users/SignIn";
//import HomePage from "./HomePage";
import LandingHome from "./LandingHome";
import AttiveSub from "./Operatore/PraticheBox/Attive/AttiveSub";
import InviateSub from "./Operatore/PraticheBox/Inviate/InviateSub";
import AttiveGeneraliSub from "./Operatore/PraticheBox/AttiveGenerali/AttiveGeneraliSub";
import SospeseSub from "./Operatore/PraticheBox/Sospese/SospeseSub";
import AnnullateSub from "./Operatore/PraticheBox/Annullate/AnnullateSub";
import Chiuse from "./Operatore/PraticheBox/Chiuse/ChiuseSub";
import HomeConsulente from "./Consulente/HomeConsulente";
import ConsulentePages from "./Consulente/ConsulentePages";
//import Nuovapratica from "./Consulente/ConsulenteBox/NuovaPratica";
import ElencoPratiche from "./Consulente/ConsulenteBox/ElencoPratiche";
import NuovaPratica from "./Consulente/ConsulenteBox/NuovaPratica";
import InviateSubCons from "./Consulente/ConsulenteBox/Inviate/InviateSubCons";
import AttiveSubCons from "./Consulente/ConsulenteBox/Attive/AttiveSubCons";
import SospeseSubCons from "./Consulente/ConsulenteBox/Sospese/SospeseSubCons";
import ChiuseSubCons from "./Consulente/ConsulenteBox/Chiuse/ChiuseSubCons";
import AnnullateSubCons from "./Consulente/ConsulenteBox/Annullate/AnnullateSubCons";
import BozzeSub from "./Consulente/ConsulenteBox/Bozze/BozzeSub";
import HomeAdmin from "./Amin/HomeAdmin";
import AdminPages from "./Amin/AdminPages";
import InviateSubAdmin from "./Amin/AdminBox/Inviate/InviateSubAdmin";
import AttiveGeneraliAdminSub from "./Amin/AdminBox/AttiveGenerali/AttiveGeneraliAdminSub";
import SospeseAdminSub from "./Amin/AdminBox/Sospese/SospeseAdminSub";
import AnnullateAdminSub from "./Amin/AdminBox/Annullate/AnnullateAdminSub";
import ChiuseAdminSub from "./Amin/AdminBox/Chiuse/ChiuseAdminSub";
import UsersAdminSub from "./Amin/AdminBox/Utenti/UsersAdminSub";
import AdminUsersPages from "./Amin/AdminUrsersPages";
import AdminModuliPages from "./Amin/AdminModuliPage";
//import ModuliAdminSub from "./Amin/AdminBox/Moduli/ModuliAdminSub";
import GestioneModuli from "./Amin/AdminBox/Moduli/GestioneModuli";
import AssociaModuli from "./Amin/AdminBox/Associa/AssociaModuli";
import { AdminGestionePage } from "./Amin/AdminGestionePage";
import { VediTipoPratiche } from "./Amin/AdminBox/Gestione/VediTipoPratiche";
import CreaPratica from "./Amin/AdminBox/Gestione/CreaPratica";
import Dashboard from "./Amin/AdminBox/dashboard/Dashboard"
/*
const router = createBrowserRouter([
  {
    path: "/",
    element: <HomeOperatore />,
    children: [
      {
        path: "/pratiche",
        element: <OperatorePages test={'OperatorePages'}/>,
        children: [
          {
            path: "attive",
            element:  <AttiveSub name={'attive'}/>,
            state: {
              index: 0
            }
          },
          {
            path: "inviate",
            element: <Inviate name="inviate"/>
          },
          {
            path: "chiuse",
            element: <Chiuse name="chiuse"/>
          },
          {
            path: "raw-medias",
            element: <RawMediaList data={RAW_DATA} />
          },
          {
            path: "datasets",
            element: <DatasetList data={DATASET_DATA} />
          },

          {
            index: true,
            element: <Navigate to="/pratiche" replace />
          }
        ]
      },
      {
        path: "files/raw-medias/:rawMediaId",
        element: <RawMediaPage />
      },
      {
        path: "files/datasets/:datasetId",
        element: <DatasetPage />
      }
    ]
  }
]);
*/
export default function App() {
  return (

    <Router>
      <Routes>
          <Route path='/home' element={<LandingHome />} />
           <Route index element={<LandingHome />} /> 
          <Route path="/signin" element={<SignIn />} />      
      
          <Route path='/operatore' element={<PrivateOperatore><HomeOperatore /></PrivateOperatore>} >
              <Route path='pratiche' element={<PrivateOperatore><OperatorePages /></PrivateOperatore>} >
              <Route path= 'attive' element={<AttiveSub name='attive' />}/>
              {/*A nested route!*/}
              <Route path= 'attive_generali' element={<AttiveGeneraliSub name='attive_generali' />}/>
              <Route path= 'sospese' element={<SospeseSub name='sospese' />}/>
              <Route path= 'annullate' element={<AnnullateSub name='annullate' />}/>
              
              <Route path='inviate' element={<InviateSub name='inviate' />} /> {/*A nested route!*/}
              <Route path='chiuse' element={<Chiuse name='chiuse' />} /> {/*A nested route!*/}
              </Route>
          </Route>

          <Route path='/consulente' element={<PrivateConsulente><HomeConsulente /></PrivateConsulente>} >
              <Route path='pratiche' element={<PrivateConsulente><ConsulentePages /></PrivateConsulente>} >
              <Route path='elenco_pratiche' element={<ElencoPratiche name='elenco_pratiche'  />}/>
              <Route path='nuova_pratica' element={<NuovaPratica />}/>
              <Route path= 'inviate' element={<InviateSubCons name='inviate' />}/>
              <Route path= 'attive' element={<AttiveSubCons name='attive' />}/>
              <Route path= 'sospese' element={<SospeseSubCons name='sospese' />}/>
              <Route path= 'chiuse' element={<ChiuseSubCons name='chiuse' />}/>
              <Route path= 'annullate' element={<AnnullateSubCons name='inviate' />} /> 
              <Route path= 'bozze' element={<BozzeSub name='bozze' />} /> 
              {/*
              <Route path='inviate' element={<InviateSub name='inviate' />} /> 
              <Route path='chiuse' element={<Chiuse name='chiuse' />} /> 
              */}
              </Route>
            

            </Route>



            <Route path='/admin' element={<PrivateAdmin><HomeAdmin /></PrivateAdmin>} >
              <Route path='pratiche' element={<PrivateAdmin><AdminPages /></PrivateAdmin>} >
              <Route path='inviate' element={<InviateSubAdmin name='inviate' />}/>
              <Route path='generali' element={<AttiveGeneraliAdminSub name='attive' />}/>
              <Route path='sospese' element={<SospeseAdminSub name='sospese' />}/>
              <Route path='chiuse' element={<ChiuseAdminSub name='chiuse' />}/>
              <Route path='annullate' element={<AnnullateAdminSub name='inviate' />} /> 
                          
            {/*
              <Route path='chiuse' element={<Chiuse name='chiuse' />} /> 
            */}
              </Route>

              <Route path='/admin/utenti' element={<PrivateAdmin><AdminUsersPages /></PrivateAdmin>} >
              <Route path='vedi' element={<UsersAdminSub name='vedi' />}/>
              <Route path='aggiungi' element={<AttiveGeneraliAdminSub name='aggiungi' />}/>
              </Route>


              <Route path='/admin/moduli' element={<PrivateAdmin><AdminModuliPages /></PrivateAdmin>} >
              <Route path='gestisci' element={<GestioneModuli name='gestisci' />}/>
              <Route path='associa' element={<AssociaModuli name='associa' />}/>
              </Route>


              <Route path='/admin/gestione' element={<PrivateAdmin><AdminGestionePage /></PrivateAdmin>} >
              <Route path='vedi' element={<VediTipoPratiche name='vedi' />}/>
              <Route path='crea' element={<CreaPratica name='crea' />}/>
              </Route>


              <Route path='/admin/dashboard' element={<PrivateAdmin><Dashboard /></PrivateAdmin>} >
              </Route>
            

            </Route>
              
              

      </Routes>
  </Router>

  );
}

function PrivateOperatore({ children }) {
  const auth = useAuthRoleOperatore();
   return auth ? children : <Navigate to="/signin" />;
 // return auth ? children : <Navigate to="/" />;
}
function useAuthRoleOperatore() {
  if(localStorage.getItem('currentUser')){
    console.log("TEST-RoleOp: ", localStorage.getItem('currentUser'));
    const tokens = JSON.parse(localStorage.getItem('currentUser'));
    console.log("Role 247: ", tokens.role[0]);
      if(tokens.token && (tokens.role[0] === "ROLE_OPERATORE")){
        return true;
      }
  }
  return false;
}


function PrivateConsulente({ children }) {
  const auth = useAuthRoleConsulente();
  console.log(children);
  // return auth ? children : <Navigate to="/login" />;
  return auth ? children : <Navigate to="/" />;
}

function useAuthRoleConsulente() {
  if(localStorage.getItem('currentUser')){
 //   console.log("TEST-RoleCONS: ", localStorage.getItem('currentUser'));
    const tokens = JSON.parse(localStorage.getItem('currentUser'));
      if(tokens.token && (tokens.role[0] === "ROLE_CONSULENTE")){
        return true;
      }
  }
  return false;
}

function PrivateAdmin({ children }) {
  const auth = useAuthRoleAdmin();
//  console.log(children);
  // return auth ? children : <Navigate to="/login" />;
  return auth ? children : <Navigate to="/" />;
}

function useAuthRoleAdmin() {
  if(localStorage.getItem('currentUser')){
 //   console.log("TEST-RoleCONS: ", localStorage.getItem('currentUser'));
    const tokens = JSON.parse(localStorage.getItem('currentUser'));
      if(tokens.token && (tokens.role[0] === "ROLE_ADMIN")){
        return true;
      }
  }
  return false;
}