import {
  MaterialReactTable,
  useMaterialReactTable,
} from 'material-react-table';
import { useMemo, useState, useEffect } from 'react';
//import TableAttiveContenitore from './TableAttiveContenitore';


import AddIcon from '@mui/icons-material/Add';
import MinusIcon from '@mui/icons-material/Remove';
import AttiveSub from './AttiveSub';
import Endpoints from '../../../Component/services/Endpoints';
//import FileUpload from '../../File_Upload_Component/file-upload.component'

const { REACT_APP_API_ENDPOINT } = process.env;


const TableAttiveContenitore = ({data, columns, renderRowSubComponent, isLoading,
  isError, isRefetching, setColumnFiltersFunc, setGlobalFilterFunc, setSortingFunc, setPaginationFunc, rowCount }) => {


 
 
   function notificaAlConsulente(data) {
   
     const notifyObj = {
     emailto: data.consulente.email,
     idpratica: data._id,
     descrizione: data.descrizione,
     stato: data.statoPraticax,
     assistito: `${data.assistito.nome} ${data.assistito.cognome}`,
     operatore: `${data.operatore[data.operatore.length - 1].nome} ${data.operatore[data.operatore.length - 1].nome}`,
     contatti: `${data.operatore[data.operatore.length - 1].email}`
     }
 
     /*
     //notifico con mail al consulente
     axios.post(`${REACT_APP_API_ENDPOINT}/api/consulente/notifica/action`, notifyObj)
     .then(function (response) {
         console.log(response.data);
     })
     .catch(function (error) {
         console.log(error);
     });
     */
 
 }

const table = useMaterialReactTable({
  columns,
  data,
  enableExpandAll: false, //disable expand all button
  enableExpanding: true,
  initialState: { 
    showColumnFilters: true,
    columnVisibility: { _id: false } //hide _id column by default
   // pagination: { pageSize: 25, pageIndex: 2 }
  },
  state: {
    isLoading,
    showAlertBanner: isError,
    showProgressBars: isRefetching,
  },
/*   onColumnFiltersChange:()=> setColumnFiltersFunc,
  onGlobalFilterChange: ()=> setGlobalFilterFunc,
  onPaginationChange: ()=> setPaginationFunc,
  onSortingChange: ()=> setSortingFunc, */
  rowCount,
 
  positionToolbarAlertBanner: 'bottom',
 
  muiDetailPanelProps: () => ({

    sx: (theme) => ({

      backgroundColor:

        theme.palette.mode === 'dark'

          ? 'rgba(255,210,244,0.1)'

          : 'rgba(0,0,0,0.1)',

    }),

  }),

     //custom expand button rotation
     muiExpandButtonProps: ({ row, table }) => ({
      children: row.getIsExpanded() ? <MinusIcon sx={{color:'red'}}/> : <AddIcon />,
      onClick: () => table.setExpanded({ [row.id]: !row.getIsExpanded() }), //only 1 detail panel open at a time
      sx: {
        transform: row.getIsExpanded() ? 'rotate(180deg)' : 'rotate(-90deg)',
        transition: 'transform 0.2s',
      },

    }),
/*
    renderDetailPanel: ({ row }) => (
      
      <AttiveSub row={row} praticaId={row.original._id}/>

    ),
 */   
     renderDetailPanel: ({ row }) => renderRowSubComponent( row )
});

  return <MaterialReactTable table={table} />;

};

export default TableAttiveContenitore;
