import React, {useState, useEffect, useMemo} from 'react';
import axios from "axios";

import Swal from 'sweetalert2';
import socketIOClient from "socket.io-client";
import FileUpload from '../../../File_Upload_Component/file-upload.component';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import CircularProgress from '@mui/material/CircularProgress';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import SendIcon from '@mui/icons-material/Send';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import AvTimerIcon from '@mui/icons-material/AvTimer';
import Endpoints from '../../../Component/services/Endpoints';
import { styled } from '@mui/system';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import TableAttiveGeneraliAdminContenitore from './TableAttiveGeneraliAdminContenitore';
import FormMessageDialog from './FormMessageDialog';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'left',
  color: theme.palette.text,
}));

const {REACT_APP_API_ENDPOINT} = process.env;


const AttiveGeneraliAdmin = ({row}) => {

    const [data, setData] = useState([])
    const [NewStatoPratica, setNewStatoPratica] = useState('');
    const [isClosedShow, setIsClosedShow] = useState(false);
    const [isNotClosedShow, setIsNotClosedShow] = useState(false);
    const [showUploader, setShowUploader] = useState(false);
   
    const [selectedFiles, setSelectedFiles] = useState([]);

    //FileInfos anche se da warning che non è usato non è vero
    const [fileInfos, setFileInfos] = useState([]);
    const [progress, setProgress] = useState(0);
    const [ricevuta, setRicevuta] = useState([]);

    const [notex, setNotex] = useState('');
    const [selectedIdPratica, setSelectedIdPratica] = useState('');

    const [isVisible, setIsVisible] = useState(true);
    const [newRicevutaInfo, setNewRicevutaInfo] = useState({
        ricevutaFile: []
    });


     //data and fetching state
    const [isError, setIsError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isRefetching, setIsRefetching] = useState(false);
    const [rowCount, setRowCount] = useState(0);
    
    //from old component
    const [currentLoggedUser, setCurrentLoggedUser] = useState({});
    //table state
    const [columnFilters, setColumnFilters] = useState([]);
    const [globalFilter, setGlobalFilter] = useState('');
    const [sorting, setSorting] = useState([]);
    const [pagination, setPagination] = useState({
      pageIndex: 0,
      pageSize: 10,
    });

    const [openDialog, setOpenDialog] = useState(false);
  //  const [operatoreAttivo, setOperatoreAttivo] = useState(false);

    const operatoreAttivo = JSON.parse(localStorage.getItem('currentUser'));
  // const {_id, cognome, nome, email, role} = operatoreClient;
  //   setOperatoreAttivo(operatoreClient);

  function setColumnFiltersFunc(val){
    setColumnFilters(val);
   }
   function setGlobalFilterFunc(val){
    setGlobalFilter(val);
   }
   function setSortingFunc(val){
    setSorting(val);
   }

   function setPaginationFunc(val){
    setPagination(val);
   }

  // fetch pratiche in lavorazione by operatore id
  //if you want to avoid useEffect, look at the React Query example instead
  useEffect(() => {
       
           const fetchData = async () => {
             if (!data.length) {
               setIsLoading(true);
             } else {
               setIsRefetching(true);
             }
           
           const op = JSON.parse(localStorage.getItem('currentUser'));
           const id = op._id;
           setCurrentLoggedUser(op);
       
            const url = new URL(`${REACT_APP_API_ENDPOINT}/api/pratiche/box/generale`,
               process.env.NODE_ENV === 'production'
                 ? 'https://www.material-react-table.com'
                 : 'http://localhost:3000',
             );
             url.searchParams.set(
               'start',
               `${pagination.pageIndex * pagination.pageSize}`,
             );
             url.searchParams.set('size', `${pagination.pageSize}`);
             url.searchParams.set('filters', JSON.stringify(columnFilters ?? []));
             url.searchParams.set('globalFilter', globalFilter ?? '');
             url.searchParams.set('sorting', JSON.stringify(sorting ?? []));
       
             try {
               const response = await fetch(url.href);
               const json = await response.json();
               setData(json);
               setRowCount(response.headers.get('X-Total-Count'));
             } catch (error) {
               setIsError(true);
               console.error(error);
               return;
             }
             setIsError(false);
             setIsLoading(false);
             setIsRefetching(false);
           };
           fetchData();
           // eslint-disable-next-line react-hooks/exhaustive-deps
         }, [
           columnFilters,
           globalFilter,
           pagination.pageIndex,
           pagination.pageSize,
           sorting,
  ]);

  //qui mi assegno la pratica
const handleShow = (event) => {

  console.log(event.cell.row.values._id);
  var idPratica = event.cell.row.values._id;
  const descrConfirm = event.cell.row.values.descrizione;
  const aConfirmNome = event.cell.row.original.assistito.nome;
  const aConfirmCognome = event.cell.row.original.assistito.cognome;

  const cConfirmNome = event.cell.row.original.consulente.nome;
  const cConfirmCognome = event.cell.row.original.consulente.cognome;

  const oConfirmNome = event.cell.row.original.operatore[0].nome;
  const oConfirmCognome = event.cell.row.original.operatore[0].cognome;

  const adminClient =JSON.parse(localStorage.getItem('currentUser'));

if(adminClient.role[0] === 'ROLE_ADMIN'){
  Swal.fire({
    title: 'Vuoi davvero eliminare?',
    text: `${descrConfirm}`,
    icon: 'warning',
    html: `<b>Descrizione: </b>${descrConfirm}<br><b>Consulente: </b>${cConfirmCognome} ${cConfirmNome} <br> <b> Assistito: </b> ${aConfirmCognome} ${aConfirmNome}` ,
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: 'SI, elimina!',
    footer: `<b>Operatore: <b/>${oConfirmCognome} ${oConfirmNome}`,
  }).then((result) => {
    if (result.isConfirmed) {
//inizio conferma seleziona
   // qui elimino la pratica
    fetch(`${REACT_APP_API_ENDPOINT}/api/pratica/eliminazione/action/id?_id=${idPratica}`, {
      headers: {
        'Content-Type': 'application/json',
      },
     
    })
      .then(res => res.json())
      .then(data => {

        if(data.success){

       //   renewTable();
          Swal.fire(
            'Eliminita!',
            data.success,
            'Grazie'
          )

       }
       if(data.error){
        Swal.fire(
          'Error!',
          data.error,
          'Grazie'
        )
          }

      })
      .catch(error => {
      // enter your logic for when there is an error (ex. error toast)
      console.log(error)
      });

      //fine conferma seleziona
    }
  })

} else {
  Swal.fire(
    'Non hai i privilegi per eseguire questa azione',
    'Grazie'
  )
 
}

}

const handleAssegna = (original) => {
  console.log(original)

    let arrayOpe = []
    const operatoriFetch = async () => {
    const response2 = await fetch(`${REACT_APP_API_ENDPOINT}/api/users/seleziona/operatori/`);
    const operator = await response2.json();
 //     console.log(pratiche);
     arrayOpe = operator;

     var options = {};

     let test = arrayOpe.map(x => options[x._id] = x.cognome + " " + x.nome);
    console.log(test);
   
    let pretext =original; 
   
     Swal.fire({
      title: `Riassegnazione`,
      iconHtml: `<svg xmlns="http://www.w3.org/2000/svg" height="48" viewBox="0 -960 960 960" width="48">
      <path d="M40-160v-112q0-34 17.5-62.5T104-378q62-31 126-46.5T360-440q66 0 130 15.5T616-378q29 15 46.5 43.5T680-272v112H40Zm720 0v-120q0-44-24.5-84.5T666-434q51 6 96 20.5t84 35.5q36 20 55 44.5t19 53.5v120H760ZM360-480q-66 0-113-47t-47-113q0-66 47-113t113-47q66 0 113 47t47 113q0 66-47 113t-113 47Zm400-160q0 66-47 113t-113 47q-11 0-28-2.5t-28-5.5q27-32 41.5-71t14.5-81q0-42-14.5-81T544-792q14-5 28-6.5t28-1.5q66 0 113 47t47 113ZM120-240h480v-32q0-11-5.5-20T580-306q-54-27-109-40.5T360-360q-56 0-111 13.5T140-306q-9 5-14.5 14t-5.5 20v32Zm240-320q33 0 56.5-23.5T440-640q0-33-23.5-56.5T360-720q-33 0-56.5 23.5T280-640q0 33 23.5 56.5T360-560Zm0 320Zm0-400Z"/></svg>`,
      html:
      `<b>_id pratica: ${pretext._id}</b><br>
       Descrizione: <b>${pretext.descrizione}</b><br>
       Consulente: <b> ${pretext.consulente.cognome} ${pretext.consulente.nome}</b><br>
       Assistito: <b> ${pretext.assistito.cognome} ${pretext.assistito.nome}</b><br>  
       Ultimo Operatore: <b> ${pretext.storico_operatore[pretext.storico_operatore.length - 1].cognome} ${pretext.storico_operatore[pretext.storico_operatore.length - 1].nome}</b><br>
       <b>stato pratica: ${pretext.statoPratica}</b><br>`,
       input: 'select',
       inputOptions: options,
       inputPlaceholder: 'Seleziona Operatore di destinazione',
       showCancelButton: true,
       inputValidator: (value) => {
         return new Promise((resolve) => {
           if (value) {           
            const found = arrayOpe.find(element => element._id === value);
            console.log(found)

            Swal.fire({
              icon: 'warning',
              title: 'Sei sicuro?',
              html:
              `<b>Operatore</b><br>
                _id: <b>${found._id}</b><br>
               Nome: <b> ${found.nome}</b><br>
               Cognome: <b>${found.cognome}</b><br>
               Nome: <b> ${found.nome}</b><br>`,
              showCancelButton: true,
              confirmButtonText: 'Si è tutto ok!',
              cancelButtonText: `Annulla`,
            }).then((result) => {
              /* Read more about isConfirmed, isDenied below */
              if (result.isConfirmed) {

                handleAssegnaPratica(found, pretext);
           //     Swal.fire('Pratica assegnata!')
              } else  {
                Swal.fire('Operazione annullata')
              }
            })
             resolve()
           } else {
             resolve('Devi selezionare un operatore :)')
           }
         })
       }
     })

    };
    operatoriFetch();
}

const handleAssegnaPratica = (operatoreSelezionato, pretext) => {

  console.log(pretext._id);
  
  var idPratica = pretext._id;
  let decrizionePratica = pretext.descrizione;
  let consulente = pretext.consulente;
  let consulenteMail = pretext.consulente.email;
  let assistito = pretext.assistito;
  const operatoreClient = operatoreSelezionato;
 // const {_id, cognome, nome, email, role} = operatoreClient;
  const {_id} = operatoreClient;

          // qui modifico la pratica
          fetch(`${REACT_APP_API_ENDPOINT}/api/admin/riassegna/operatore:id?_id=${idPratica}`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(operatoreClient)
          })
            .then(res => res.json())
            .then(data => {


              const {_id, cognome, nome, email, role} = operatoreClient;


              if(data.success){

                let notifyObj = {
                  emailto: consulenteMail,
                  idpratica: idPratica,
                  descrizione: decrizionePratica,
                  stato: 'Assegnata',
                  assistito: assistito,
                  operatore: `${operatoreClient.cognome} ${operatoreClient.nome}`,
                  contatti: `Cell: ${operatoreClient.cellulare} - Tel: ${operatoreClient.telefono} Email: ${operatoreClient.email}`
                };

           //     notificaAlConsulente(notifyObj);

              }

              let assegnaObj = {
                _id: _id,
                cognome: cognome,
                nome: nome,
                email: email,
                role: role,
                date: new Date().toLocaleString('it-IT'),
                consulente: consulente,
                assistito: assistito,
                praticaId: idPratica,
                descrizione: decrizionePratica
              }

              const socket = socketIOClient(`${REACT_APP_API_ENDPOINT}/api/`);
              socket.emit('adminAction', JSON.stringify(assegnaObj));
            //  console.log("data? ",data)

              const doFetch2 = async () => {
            //  setSpinnerLoading(true);
      
              const response = await fetch(`${REACT_APP_API_ENDPOINT}/api/pratiche/box/generale/`);
              const pratiche = await response.json();
           //     console.log(pratiche);
                setData(pratiche);
              //  setSpinnerLoading(false);
              };
              doFetch2();

            })
            .catch(error => {
            // enter your logic for when there is an error (ex. error toast)
            console.log(error)
            });

   

}

  function notificaAlConsulente(obj){
    //notifico con mail al consulente
    axios.post(`${REACT_APP_API_ENDPOINT}/api/consulente/notifica/action`, obj)
    .then(function (response) {
      console.log(response.data);
    })
    .catch(function (error) {
      console.log(error);
    });
}

const renewTableAfterDelete = (val) => {
  setData((data) => data.filter(({_id}) => _id !== val.praticaId));

}

const renewTable = (val) =>{       
  setData((data) => data.filter(({_id}) => _id !== val));  
}


function praticaSelezionata( praticaID ) {
setSelectedIdPratica(praticaID)
console.log("praticaSelezionata", praticaID)
}
        
function deletePratica( row ) {
   
  var idPratica = row._id;
  const adminClient =JSON.parse(localStorage.getItem('currentUser'));

    if(adminClient.role[0] === 'ROLE_ADMIN'){
      Swal.fire({
        title: `Vuoi davvero eliminare la pratica n° ${row.pratica_numero}?`,
        icon: 'danger',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'SI, elimina!',
        footer: `<b>Admin<b/>`,
      }).then((result) => {
        if (result.isConfirmed) {
    //inizio conferma seleziona
      // qui elimino la pratica
        fetch(`${REACT_APP_API_ENDPOINT}/api/pratiche/eliminazione/action/id?_id=${idPratica}`, {
          headers: {
            'Content-Type': 'application/json',
          },
        
        })
      .then(res => res.json())
      .then(data => {
        if(data.success){

          let updTbl = {
            praticaId: idPratica
          }
       
          renewTableAfterDelete(updTbl);

          Swal.fire(
            'Eliminata!',
            'Un documento eliminato con successo.',
           // data.success,
            'Grazie'
          )

       }
       if(data.error){
        Swal.fire(
          'Error!',
          data.error,
          'Grazie'
        )
          }

      })
      .catch(error => {
      // enter your logic for when there is an error (ex. error toast)
      console.log(error)
      });

      //fine conferma seleziona
    }
  })

} else {
  Swal.fire(
    'Non hai i privilegi per eseguire questa azione',
    'Grazie'
  )
 
}
}



const columns = useMemo(
           () => [
             {
               accessorKey: '_id',
               header: '_id',
             },
             {
              accessorKey: 'pratica_numero',
              header: 'id',
              minSize: 20, //min size enforced during resizing
              maxSize: 80, //max size enforced during resizing
              size: 20, //medium column
            },
             //column definitions...
             {
               accessorKey: 'descrizione',
               header: 'Descrizione',
             },
             {
               accessorKey: 'assistito.regione',
               header: 'Regione',
             },
            
             {
              accessorFn: (row) => (row.assistito?.cognome ? row.assistito.cognome : "" ),
              id: 'assistito.cognome',
              header: 'Assistito',
             },
        /*  */
            {
              //accessorFn function that combines multiple data together
            accessorFn: (row) => (row.assistito?.codice_fiscale_assistito ? row.assistito.codice_fiscale_assistito : ""),
            id: 'cf_assistito',
            header: 'CF.Assistito',
            },

            {
              //accessorFn function that combines multiple data together
            accessorFn: (row) => (row.consulente ? `${row.consulente?.nome} ${row.consulente?.cognome}` : ""),
            id: 'consulente',
            header: 'Consulente',
            },   
             {
               accessorKey: 'statoPratica',
               header: 'Stato',
             },
             {
              accessorFn: (row) => (row.storico_pratica[row.storico_pratica.length - 1].data),
              header: 'Inviata il', 
             },
             {

              accessorFn: (row) => new Date(row.createdAt), //convert to Date for sorting and filtering
              id: 'createAt',
              header: 'Creata il',
              filterVariant: 'date',
              filterFn: 'lessThan', 
              sortingFn: 'datetime',
              Cell: ({ cell }) => cell.getValue()?.toLocaleDateString(), //render Date as a string 
              Header: ({ column }) => <em>{column.columnDef.header}</em>, //custom header markup 
              muiFilterTextFieldProps: {
                sx: {
                  minWidth: '250px', 
                }, 
              }, 
            },
             
                   /*
             {
               accessorKey: d => `${d.storico_pratica[d.storico_pratica.length - 1].data}`,
               header: 'Inviata il',
             },
             */
             //end
           ],
           [],
);
    

    const renderRowSubComponent = (row) => {
 //   console.log(row.original)

    let {
        allegato,
        operatore,
        data_scadenza,
        descrizione,
        note,
        ricevute,
        statoPratica,
        storico_pratica,
        storico_operatore,
        storico_note,
        ...rest
    } = row.original;
    let consulente = rest.consulente;
    let tipo_pratica = rest.tipo_pratica;
    let assistitoObj = rest.assistito;
    let collaboratore = rest.colloboratore;
    let pratica_id = rest._id;

   // let statiPossibili = ['Lavorazione', 'Chiusa', 'Sospesa', 'Annullata'];
  //  console.log(row.row.row.original);
 //   console.log(rest.consulente);

    function notificaAlConsulente(data) {

        const notifyObj = {
        emailto: data.consulente.email,
        idpratica: data._id,
        descrizione: data.descrizione,
        stato: data.statoPratica,
        assistito: `${data.assistito.nome} ${data.assistito?.cognome}`,
        operatore: `${data.operatore[data.operatore.length - 1].nome} ${data.operatore[data.operatore.length - 1].nome}`,
        contatti: `${data.operatore[data.operatore.length - 1].email}`
        }

        /*
        //notifico con mail al consulente
        axios.post(`${REACT_APP_API_ENDPOINT}/api/consulente/notifica/action`, notifyObj)
        .then(function (response) {
            console.log(response.data);
        })
        .catch(function (error) {
            console.log(error);
        });
        */ 
    
    }

    const renewTable = (val) => {
            setData((data) => data.filter(({_id}) => _id !== val.praticaId));

    }



    const eliminaRiceuta = (path, index) => {

    alert(path, index);
    console.log(ricevuta);
    console.log(fileInfos)

    setRicevuta([
        ...ricevuta.slice(0, index),
        ...ricevuta.slice(index + 1)
    ]);

    setFileInfos([
        ...fileInfos.slice(0, index),
        ...fileInfos.slice(index + 1)
    ]);

    axios({
        method: 'get',
        url: `${REACT_APP_API_ENDPOINT}/api/pratica/delete/ricevuta/path?path=${path}`
    })
        .then(function (response) {
            console.log(response);
        });
    }

    /* NUOVO UPLOADER */
    const handleFiles = () => {
            setIsVisible(true);
            let selectedFile = newRicevutaInfo.ricevutaFile;

            console.log('Test Files');

            const data = new FormData()
            for (var x = 0; x < selectedFile.length; x++) {
                data.append('rcvCollection', selectedFile[x]);
            }
            const headers = {"Content-Type": "multipart/form-data"};

            // ATTENZIONE A DOVE CARICA
            axios.post(`${REACT_APP_API_ENDPOINT}/api/ricevute`, data, {
                headers: headers,
                // receive two    parameter endpoint url ,form data
                onUploadProgress: ProgressEvent => {
                    setProgress((ProgressEvent.loaded / ProgressEvent.total * 100))
                },
            }).then(response => { // then print response status
                console.log(response);
                setFileInfos(response.data);

                let ricevuteArray = [];

                for (let i = 0; i < response.data.length; i++) {
                    ricevuteArray.push({
                        path: response.data[i].filename,
                        creato: new Date().toLocaleString('it-IT', { timeZone: 'UTC' })
                    })
                }

                setRicevuta(ricevuteArray);

            })
            //********************* */
    }

   /*  const handleSelect = (event) => {
        event.stopPropagation();
        setShowUploader(true);

    //alert('Your favorite flavor is: ' + event.target.value);
    if (event.target.value.toString() === 'Chiusa') {

        alert('Stato pratica selezionato: ' + event.target.value);
        setIsClosedShow(true);
        setNewStatoPratica(event.target.value);
    } else {
        setIsClosedShow(false);
    }

    if (event.target.value.toString() !== 'Chiusa') {

        alert('Stato pratica selezionato: ' + event.target.value);
        setIsNotClosedShow(true);
        setNewStatoPratica(event.target.value);
    } else {
        setIsNotClosedShow(false)
    }


    event.preventDefault();
    } */

    const handleSubmit = (event) => {
        event.preventDefault();
       
        let aggiornaObj = {
         
           _id: selectedIdPratica,
            statoPratica: NewStatoPratica,
            ricevuta: ricevuta,
            note: notex,
            storico_pratica: {
                stato: NewStatoPratica,
                data: new Date().toLocaleString('it-IT', { timeZone: 'UTC' })
            }
        }
        console.log(aggiornaObj);

        const headers = {
            "Content-Type": "application/json",
        }

        axios.post(`${REACT_APP_API_ENDPOINT}/api/pratiche/cambiostato/`, aggiornaObj, {
            headers: headers,
        })
            .then(response => { // then print response status
                console.log(response);

                fetch(`${REACT_APP_API_ENDPOINT}/api/pratiche/inserita/id?_id=${aggiornaObj._id}`, {
                        headers: {
                            'Content-Type': 'application/json'
                            // 'Content-Type': 'application/x-www-form-urlencoded',
                        }
                    }
                ).then(response => response.json())
                    .then(data => notificaAlConsulente(data));


                Swal.fire({
                    title: 'Cambio stato',
                    text: `Hai cambiato questa pratica in: ${NewStatoPratica}`,
                    icon: 'info',
                    showCancelButton: false,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'OK!',
                    footer: `<b>Operatore<b/>`,
                }).then((result) => {
                    if (result.isConfirmed) {
                        const socket = socketIOClient(`${REACT_APP_API_ENDPOINT}/api/`);
                        socket.emit('cambiostato', JSON.stringify({pratica: 'aggiornata'}));
                        let updTbl = {
                            praticaId: selectedIdPratica
                        }

                        renewTable(updTbl);
                    } else {
                        const socket = socketIOClient(`${REACT_APP_API_ENDPOINT}/api/`);
                        socket.emit('cambiostato', JSON.stringify({pratica: 'aggiornata'}));
                        let updTbl = {
                            praticaId: selectedIdPratica
                        }

                        renewTable(updTbl);

                    }
                })


            })

        //fine
    }

    const RenderAllegati = () => {
        return (

            <div>
        {allegato?.map((rowData, index) => ( 

            <Stack key={index}
                direction="row"
                sx={{ width: '100%' }}
                divider={<Divider orientation="vertical" flexItem />}
                spacing={12}
            >
                <Item key={index+1} sx={{boxShadow: 'none'}}><PictureAsPdfIcon sx={{color: 'red'}} /></Item>
                <Item key={index+2} sx={{boxShadow: 'none'}}><a style={{color: 'darkorange'}} href={`${REACT_APP_API_ENDPOINT}/api/static/allegati/${rowData.path}`} download target="_blank" rel="noopener noreferrer">{rowData.path}</a></Item>
                <Item key={index+3} sx={{boxShadow: 'none'}}>{'Data: '}{new Date(rowData.creato).toLocaleString('it-IT', { timeZone: 'UTC' })}</Item>
            </Stack>   
            
        ))}
        </div>          
        )
    }

    const RenderStoricoRicevute = () => {
        return (
            <TableContainer component={Paper} sx={{background: 'none'}}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>#</TableCell>
                    <TableCell align="right">tipo</TableCell>
                    <TableCell align="right">Url</TableCell>
                    <TableCell align="right">Creato</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {ricevute?.map((rowData, index) => (
                    <TableRow
                      key={index}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">{index + 1}</TableCell>
                      <TableCell align="right"><PictureAsPdfIcon sx={{color: 'red'}} /></TableCell>
                      <TableCell align="right">
                      <a style={{color: 'darkorange'}} href={`${REACT_APP_API_ENDPOINT}/api/static/pratiche/ricevute/${rowData.path} `} download
                                           target="_blank" rel="noopener noreferrer">
                                        {rowData.path}</a></TableCell>
                      <TableCell align="right">{new Date(rowData.creato).toLocaleString('it-IT', { timeZone: 'UTC' })}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          );
    }

    const RenderStoricoPratica = () => {


        return (

          <div>
          
            {storico_pratica?.map((rowData, index) => ( 
        <Box sx={{ flexGrow: 1 }} key={index}>
          <Grid container fixed="true" spacing={2} >
              <Grid item xs={6}>
                <Item>{'Stato: '}{rowData.stato}</Item>
              </Grid>
              <Grid item xs={6}>
                <Item>{'Data: '}{rowData.data}</Item>
              </Grid>
            </Grid>

             </Box>
            ))}

       
          
          </div>


            
          );
    }

    const RenderStoricoNote = () => {
        return (
            <TableContainer component={Paper} sx={{background: 'none'}}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>#</TableCell>
                    <TableCell align="right">Nota</TableCell>
                    <TableCell align="right">Data</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {storico_note?.map((rowData, index) => (
                    <TableRow
                      key={index}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        {index + 1}
                      </TableCell>
                      <TableCell align="right">{rowData.nota}</TableCell>
                      <TableCell align="right">{rowData.data}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          );
    }

    const RenderStoricoOperatore = () => {
        return (
            <TableContainer component={Paper} sx={{background: 'none'}}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>#</TableCell>
                    <TableCell align="right">_id</TableCell>
                    <TableCell align="right">Nome</TableCell>
                    <TableCell align="right">Cognome</TableCell>
                    <TableCell align="right">Email</TableCell>
                    <TableCell align="right">Data</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {storico_operatore?.map((rowData, index) => (
                    <TableRow
                      key={index}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        {index + 1}
                      </TableCell>
                      <TableCell align="right">{rowData.id}</TableCell>
                      <TableCell align="right">{rowData.nome}</TableCell>
                      <TableCell align="right">{rowData?.cognome}</TableCell>
                      <TableCell align="right">{rowData.email}</TableCell>
                      <TableCell align="right">{rowData.data}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          );
    }
// funzione per gestire l'array delle ricuvute
    const updateUploadedFiles = (files) =>
    setNewRicevutaInfo({...newRicevutaInfo, ricevutaFile: files});
    
    const RenderNonClosedCase2 = () => {

        return (
            <div >
                <FileUpload
                    accept=".pdf"
                    label="Area caricamento ricevute"
                    multiple
                    updateFilesCb={updateUploadedFiles}
                />

                {isVisible ?
                  <>
                  <Box sx={{ position: 'relative', display: 'inline-flex' }}>
                  <CircularProgress variant="determinate" value={progress} />
                  <Box
                    sx={{
                      top: 0,
                      left: 0,
                      bottom: 0,
                      right: 0,
                      position: 'absolute',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <Typography variant="caption" component="div" color="text.secondary">
                      {`${progress}%`}
                    </Typography>
                  </Box>
                </Box>
                  
                <Paper elevation={0} />
 {/*                    
                        <div>
                            {fileInfos &&
                                fileInfos.map((fileInfo, index) => (

                                  <Box sx={{ width: '100%' }}>
                                  <Typography  component="div" style={{ padding: 6 * 2, fontSize:'small' }}>{fileInfo.path}</Typography>
                                  <CircularProgress variant="determinate" value={progress} />
                                </Box>
                                ))}

                        </div>
*/}
                        <div >
                            

                                {fileInfos.map((el, i) => {
                                    return (
                                    <>
                                    <Stack key={i}
                                        direction="row"
                                        sx={{ width: '100%' }}
                                        divider={<Divider  flexItem />}
                                        spacing={2}
                                    >
                                        <Item key={i+1} sx={{boxShadow: 'none'}}><PictureAsPdfIcon sx={{color: 'red'}} /></Item>
                                        <Item key={i+2} sx={{boxShadow: 'none'}}><a style={{color: 'orange'}}href={`${REACT_APP_API_ENDPOINT}/api/static/pratiche/ricevute/${el.filename}` }
                                         target="_blank" rel="noopener noreferrer" downlod="true">{el.filename}</a>
                                         </Item>
                                        <Item key={i+3} sx={{boxShadow: 'none'}}><button type="button" className="btn btn-danger btn-sm"
                                        style={{float: 'right'}}
                                        onClick={() => eliminaRiceuta(el.filename, i)}>Elimina sul server</button></Item>
                                       
                                    </Stack> 
                                    <Divider />
                                    </>
                                    )

                                })}
                               
                        
                                </div>

                        {newRicevutaInfo.ricevutaFile && newRicevutaInfo.ricevutaFile.length >0 ?
                        <Stack spacing={2} style={{boxShadow: 'none'}}>
                        <Item  style={{boxShadow: 'none'}}>
                        <Button 
                        sx={{float:'rigth'}}
                        onClick={handleFiles}
                        variant="contained"
                        startIcon={<CloudUploadIcon />}
                        >Carica i documenti sul server remoto!</Button></Item>
                        </Stack>
                      : null }

                        <Box
                        component="div"
                        sx={{
                          '& > :not(style)': { m: 1, width: '90%' },
                        }}
                        noValidate
                        autoComplete="off"
                      >
                        <TextField 
                        sx={{width: '90%'}}
                        name='note'
                        onChange={e => setNotex(e.target.value)}
                        defaultValue={''}
                        id="outlined-basic" label="Aggiungi Note" variant="outlined" />


                        <Stack spacing={2} sx={{boxShadow: 'none'}}>
                        <Item  style={{boxShadow: 'none'}}><Button 
                        sx={{float:'rigth'}}
                        disabled={!selectedFiles}
                        onClick={handleSubmit}
                        variant="contained">Cambia lo stato pratica!</Button></Item>
                      </Stack>
                        
                      </Box>



                    
                    <Paper />
                    </>
                    : null}
                   
            </div>
        )
    }



    return (
        <>
        <Container maxWidth="lg">

          <FormMessageDialog sendId={pratica_id} descrizione={row.original.descrizione} target={consulente} operatoreAttivo={operatoreAttivo} openDialog={openDialog} setOpenDialog={setOpenDialog} />
          <Divider className={"container"} component="div" role="presentation"
            sx={{ marginTop: '20px', marginBottom: '20px' }}>
            {/* any elements nested inside the role="presentation" preserve their semantics. */}
            <Typography variant="h5">Consulente:</Typography>
          </Divider>
          <Grid container fixed="true" spacing={2}>
            <Grid item xs={4}>
              <Item> <small style={{ color: 'rgb(131 135 140)' }}>Cognome: </small>
                {`${consulente?.cognome} `}</Item>
            </Grid>
            <Grid item xs={4}>
              <Item><small style={{ color: 'rgb(131 135 140)' }}>Nome: </small>
                {`${consulente?.nome} `}</Item>
            </Grid>
            <Grid item xs={4}>
              <Item><small style={{ color: 'rgb(131 135 140)' }}> Email: </small>
                {`${consulente?.email} `}</Item>
            </Grid>
            <Grid item xs={4}>
              <Item><small style={{ color: 'rgb(131 135 140)' }}> Cellulare: </small>
                {`${consulente?.cellulare} `}</Item>
            </Grid>
            <Grid item xs={4}>
              <Item><small style={{ color: 'rgb(131 135 140)' }}> Telefono: </small>
                {`${consulente?.telefono} `}</Item>
            </Grid>
            <Grid item xs={4}>
              <Button variant="contained" endIcon={<SendIcon />} size="small" onClick={() => setOpenDialog(true)} >
                Invia Messaggio al consulente
              </Button>
            </Grid>
          </Grid>
        </Container>

        <Container maxWidth="lg">
          <Divider className={"container"} component="div" role="presentation"
            sx={{ marginTop: '20px', marginBottom: '20px' }}>
            {/* any elements nested inside the role="presentation" preserve their semantics. */}
            <Typography variant="h5">Tipo pratica:</Typography>
          </Divider>
          <Grid container fixed="true" spacing={2}>
            <Grid item xs={4}>
              <Item> <small style={{ color: 'rgb(131 135 140)' }}>descrizione: </small>
                {`${descrizione} `}</Item>
            </Grid>
            <Grid item xs={4}>
              <Item><small style={{ color: 'rgb(131 135 140)' }}>tipo pratica: </small>
                {`${tipo_pratica} `}</Item>
            </Grid>
          </Grid>
        </Container>


        <Container maxWidth="lg">
          <Divider className={"container"} component="div" role="presentation"
            sx={{ marginTop: '20px', marginBottom: '20px' }}>
            {/* any elements nested inside the role="presentation" preserve their semantics. */}
            <Typography variant="h5">Assistito:</Typography>
          </Divider>
          <Grid container fixed="true" spacing={2}>
            <Grid item xs={4}>
              <Item> <small style={{ color: 'rgb(131 135 140)' }}>Cognome: </small>
                {`${assistitoObj?.cognome} `}</Item>
            </Grid>
            <Grid item xs={4}>
              <Item><small style={{ color: 'rgb(131 135 140)' }}>Nome: </small>
                {`${assistitoObj?.nome} `}</Item>
            </Grid>
            <Grid item xs={4}>
              <Item> <small style={{ color: 'rgb(131 135 140)' }}>C.F.: </small>
                {`${assistitoObj?.codice_fiscale_assistito} `}</Item>
            </Grid>
            <Grid item xs={4}>
              <Item><small style={{ color: 'rgb(131 135 140)' }}>Prov.: </small>
                {`${assistitoObj?.provincia} `}</Item>
            </Grid>
            <Grid item xs={4}>
              <Item><small style={{ color: 'rgb(131 135 140)' }}>Comune: </small>
                {`${assistitoObj?.comune} `}</Item>
            </Grid>
            <Grid item xs={4}>
              <Item> <small style={{ color: 'rgb(131 135 140)' }}>Via: </small>
                {`${assistitoObj?.indirizzo} - ${assistitoObj?.cap} `}</Item>
            </Grid>
            <Grid item xs={4}>
              <Item><small style={{ color: 'rgb(131 135 140)' }}>Tipo assistito: </small>
                {`${assistitoObj?.categoria_assistito} `}</Item>
            </Grid>
            <Grid item xs={4}>
              <Item><small style={{ color: 'rgb(131 135 140)' }}>sc: </small>
                {`${assistitoObj?.stato_civile} `}</Item>
            </Grid>
            <Grid item xs={4}>
              <Item><small style={{ color: 'rgb(131 135 140)' }}>Email: </small>
                {`${assistitoObj?.email} `}</Item>
            </Grid>
            <Grid item xs={4}>
              <Item> <small style={{ color: 'rgb(131 135 140)' }}>Cellulare: </small>
                {`${assistitoObj?.cellulare} `}</Item>
            </Grid>
            <Grid item xs={4}>
              <Item><small style={{ color: 'rgb(131 135 140)' }}>Tel.: </small>
                {`${assistitoObj?.telefono} `}</Item>
            </Grid>
          </Grid>
        </Container>

        <Container maxWidth="lg">
          <Divider className={"container"} component="div" role="presentation"
            sx={{ marginTop: '20px', marginBottom: '20px' }}>
            {/* any elements nested inside the role="presentation" preserve their semantics. */}
            <Typography variant="h5">Collaboratore volontario:</Typography>
          </Divider>
          <Grid container fixed="true" spacing={2}>
            <Grid item xs={4}>
              <Item> <small style={{ color: 'rgb(131 135 140)' }}>Cognome: </small>
                {`${collaboratore?.cognome} `}</Item>
            </Grid>
            <Grid item xs={4}>
              <Item><small style={{ color: 'rgb(131 135 140)' }}>Nome: </small>
                {`${collaboratore?.nome} `}</Item>
            </Grid>
            <Grid item xs={4}>
              <Item><small style={{ color: 'rgb(131 135 140)' }}>C.F: </small>
                {`${collaboratore?.codice_fiscale} `}</Item>
            </Grid>
          </Grid>
        </Container>

        <Container maxWidth="lg">
          <Divider className={"container"} component="div" role="presentation"
            sx={{ marginTop: '20px', marginBottom: '20px' }}>
            {/* any elements nested inside the role="presentation" preserve their semantics. */}
            <Typography variant="h5">Stato pratica:</Typography>
          </Divider>
          <Grid container fixed="true" spacing={2}>
            <Grid item xs={4}>
              <Item>  <small style={{ color: 'rgb(131 135 140)' }}>stato: </small>
                {`${statoPratica} `}</Item>
            </Grid>

          </Grid>
        </Container>


        <Container maxWidth="lg">
          <Divider className={"container"} component="div" role="presentation"
            sx={{ marginTop: '20px', marginBottom: '20px' }}>
            {/* any elements nested inside the role="presentation" preserve their semantics. */}
            <Typography variant="h5">Documenti allegati:</Typography>
          </Divider>
          <Grid container fixed="true" spacing={2}>
            <Grid item xs={12}>
              <Item>  {RenderAllegati()}</Item>
            </Grid>

          </Grid>
        </Container>

        <Container maxWidth="lg">
          <Divider className={"container"} component="div" role="presentation"
            sx={{ marginTop: '20px', marginBottom: '20px' }}>
            {/* any elements nested inside the role="presentation" preserve their semantics. */}
            <Typography variant="h5">Storico pratica:</Typography>
          </Divider>
          <Grid container fixed="true" spacing={2} sx={{background: 'none'}}>
            <Grid item xs={12}>
              <Item>  {RenderStoricoPratica()}</Item>
            </Grid>

          </Grid>
        </Container>

        <Container maxWidth="lg">
          <Divider className={"container"} component="div" role="presentation"
            sx={{ marginTop: '20px', marginBottom: '20px' }}>
            {/* any elements nested inside the role="presentation" preserve their semantics. */}
            <Typography variant="h5">Storico Note:</Typography>
          </Divider>
          <Grid container fixed="true" spacing={2}>
            <Grid item xs={12}>
              <Item>{RenderStoricoNote()}</Item>
            </Grid>

          </Grid>
        </Container>


        <Container maxWidth="lg">
          <Divider className={"container"} component="div" role="presentation"
            sx={{ marginTop: '20px', marginBottom: '20px' }}>
            {/* any elements nested inside the role="presentation" preserve their semantics. */}
            <Typography variant="h5">Storico Ricevuta:</Typography>
          </Divider>
          <Grid container fixed="true" spacing={2} >
            <Grid item xs={12}>
              <Item>{RenderStoricoRicevute()}</Item>
            </Grid>

          </Grid>
        </Container>

        <Container maxWidth="lg">
          <Divider className={"container"} component="div" role="presentation"
            sx={{ marginTop: '20px', marginBottom: '20px' }}>
            {/* any elements nested inside the role="presentation" preserve their semantics. */}
            <Typography variant="h5">Storico Operatore:</Typography>
          </Divider>
          <Grid container fixed="true" spacing={2}>
            <Grid item xs={12}>
              <Item>{RenderStoricoOperatore()}</Item>
            </Grid>

          </Grid>
        </Container>

        <Container maxWidth="lg">
          <Divider className={"container"} component="div" role="presentation"
            sx={{ marginTop: '20px', marginBottom: '20px' }}>
            {/* any elements nested inside the role="presentation" preserve their semantics. */}
            <Typography variant="h5">Stato Pratica attuale:</Typography>
          </Divider>
          <Grid container fixed="true" spacing={2}>
            <Grid item xs={12}>
              <Item>{`${statoPratica}`}</Item>
            </Grid>

          </Grid>
        </Container>

        {/* 
        <Container maxWidth="lg">
          <Divider className={"container"} component="div" role="presentation"
            sx={{ marginTop: '20px', marginBottom: '20px' }}>
            <Typography variant="h5">Cambia lo stato pratica qui:</Typography>
          </Divider>
          <Grid container fixed="true" spacing={2}>
            <Grid item xs={12} component="div">
              <Item component="div">
                <form onSubmit={handleSubmit} encType="multipart/form-data">
                  <select defaultValue={statoPratica}
                    className="form-select form-select-lg mb-3"
                    aria-label="Default select example"
                    onChange={(event) => {
                      handleSelect(event);
                      setSelectedIdPratica(pratica_id);
                    }}
                  >
                    {statiPossibili.map((item, i) => {
                      return <option key={i} value={item}>{item}</option>
                    })}
                  </select>
                  <p />
                  {showUploader ? RenderNonClosedCase2() : null}
                </form>
              </Item>
            </Grid>

          </Grid>
        </Container>
*/}

        </>


    );
}

    return (
        <TableAttiveGeneraliAdminContenitore 
        data={data} 
        columns={columns} 
        renderRowSubComponent={renderRowSubComponent} 
        isLoading={isLoading} 
        isError={isError}
        renewTable={renewTable} 
        praticaSelezionata={praticaSelezionata} 
        handleAssegna={handleAssegna}
        deletePratica={deletePratica} 
        isRefetching={isRefetching} 
        setColumnFiltersFunc={setColumnFiltersFunc} 
        setGlobalFilterFunc={setGlobalFilterFunc} 
        setSortingFunc={setSortingFunc} 
        setPaginationFunc={setPaginationFunc} 
        rowCount={rowCount}
        />


    );
}

//export default AttiveGeneraliAdminSub;


const AttiveGeneraliAdminSub = () => (

  //App.tsx or AppProviders file
  <LocalizationProvider dateAdapter={AdapterDayjs}>
    <AttiveGeneraliAdmin />
  </LocalizationProvider>
);


export default AttiveGeneraliAdminSub;