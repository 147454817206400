import {
  MaterialReactTable,
  useMaterialReactTable,
} from 'material-react-table';
import { MRT_Localization_IT } from 'material-react-table/locales/it';
//import TableAttiveContenitore from './TableAttiveContenitore';

import AddIcon from '@mui/icons-material/Add';
import MinusIcon from '@mui/icons-material/Remove';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import { Box, IconButton, Tooltip } from '@mui/material';
//import FileUpload from '../../File_Upload_Component/file-upload.component'
import { Delete as DeleteIcon }from '@mui/icons-material';

const TableSospeseliAdminContenitore = ({
  data, 
  columns, 
  renderRowSubComponent, 
  isLoading,
  isError, 
  isRefetching,
  deletePratica,
  handleAssegna,
  renewTable,
  praticaSelezionata,
  setColumnFiltersFunc, 
  setGlobalFilterFunc, 
  setSortingFunc, 
  setPaginationFunc, 
  rowCount }) => {
 
   function notificaAlConsulente(data) {
   
     const notifyObj = {
     emailto: data.consulente.email,
     idpratica: data._id,
     descrizione: data.descrizione,
     stato: data.statoPraticax,
     assistito: `${data.assistito.nome} ${data.assistito.cognome}`,
     operatore: `${data.operatore[data.operatore.length - 1].nome} ${data.operatore[data.operatore.length - 1].nome}`,
     contatti: `${data.operatore[data.operatore.length - 1].email}`
     }
 
     /*
     //notifico con mail al consulente
     axios.post(`${REACT_APP_API_ENDPOINT}/api/consulente/notifica/action`, notifyObj)
     .then(function (response) {
         console.log(response.data);
     })
     .catch(function (error) {
         console.log(error);
     });
     */
 
 }

const table = useMaterialReactTable({
  columns,
  data,
  localization: MRT_Localization_IT,
  enableExpandAll: false, //disable expand all button
  enableExpanding: true,
  enableRowActions: true,
  initialState: { 
    showColumnFilters: true,
    columnVisibility: { _id: false } //hide _id column by default
   // pagination: { pageSize: 25, pageIndex: 2 }
  },
  manualFiltering: false, //turn off built-in client-side filtering
  manualPagination: false, //turn off built-in client-side pagination
  
  state: {
    isLoading,
    showAlertBanner: isError,
    showProgressBars: isRefetching,
  },
  // onColumnFiltersChange:()=> setColumnFiltersFunc,
  // onGlobalFilterChange: ()=> setGlobalFilterFunc,
  // onPaginationChange: ()=> setPaginationFunc,
  // onSortingChange: ()=> setSortingFunc,
  rowCount,
  positionToolbarAlertBanner: 'bottom',
  positionActionsColumn: 'last',

  muiDetailPanelProps: () => ({

    sx: (theme) => ({

      backgroundColor:

        theme.palette.mode === 'dark'

          ? 'rgba(255,210,244,0.1)'

          : 'rgba(0,0,0,0.1)',

    }),

  }),

     //custom expand button rotation
  muiExpandButtonProps: ({ row, table }) => ({
      children: row.getIsExpanded() ? <MinusIcon sx={{color:'red'}}/> : <AddIcon />,
      onClick: () => table.setExpanded({ [row.id]: !row.getIsExpanded() }), //only 1 detail panel open at a time
      sx: {
        transform: row.getIsExpanded() ? 'rotate(180deg)' : 'rotate(-90deg)',
        transition: 'transform 0.2s',
      },

  }),

    renderRowActions: ({ row }) => (
      <Box sx={{ display: 'flex', flexWrap: 'nowrap', gap: '8px' }}>
      <Tooltip title="Elimina pratica">
      <IconButton
        color="error"
        onClick={() => deletePratica(row.original)}
      >
       <DeleteIcon />
      </IconButton>
      </Tooltip>
      <Tooltip title="Assegna">
      <IconButton 
      color="secondary"
      onClick={() => handleAssegna(row.original)}>
      <SupervisorAccountIcon />
    </IconButton>
    </Tooltip>
    </Box>
    ),
/*
    renderDetailPanel: ({ row }) => (
      
      <AttiveSub row={row} praticaId={row.original._id}/>

    ),
 */   
     renderDetailPanel: ({ row }) => renderRowSubComponent( row )
});

  return <MaterialReactTable table={table} />;

};

export default TableSospeseliAdminContenitore;
