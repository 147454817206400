import { useMemo, useState, useEffect, useRef } from 'react';
import Endpoints from '../../../Component/services/Endpoints';
import axios from "axios";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from 'material-react-table';
import { useFormik, Field, FormikProvider } from 'formik';
import * as yup from 'yup';

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { Edit, Delete } from '@mui/icons-material';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';
import Container from '@mui/material/Container';
import Switch from '@mui/material/Switch';
import { styled } from '@mui/material/styles';
import {
  Box,
  IconButton,
  DialogActions,
  DialogContent,
  DialogTitle,
  Dialog,
  Stack,
  TextField,
  Tooltip,
} from '@mui/material';
//import '../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import {
  Edit as EditIcon,
  Delete as DeleteIcon,
} from '@mui/icons-material';
import FormControl from '@mui/material/FormControl';
import { FormControlLabel } from '@mui/material';
import FormLabel from '@mui/material/FormLabel';
import Select from '@mui/material/Select';
import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import './Editor.css'

//email : yup.string().required().email().label('Email'),
const { REACT_APP_API_ENDPOINT } = process.env;


const validationSchema = yup.object({

  descrizione: yup.string('Inserire una descrizione').label('descrizione').required('descrizione è richiesta'),
  
  documenti_necessari: yup
  .string('Inserire una testo')
  .label('docunti testo')
  .required('Campo richiesto'),

  
  modulo: yup
  .string('Inserisci un primo modulo')
  .label('Modulo')
  .required('Campo richiesto'),
/*
  tipo_pratica: yup
  .string('Inserire una tipo_pratica')
  .label('tipo_pratica')
  .required('Campoo richiesto'),
*/
  tipopratica_menu: yup
  .string('Inserire una tipo_pratica_menu')
  .label('tipo_pratica_menu')
  .required('Campo richiesto'),

  
});

const TipoPraticheTableHeader = ({data, handleRefetch}) => {
  
 // const [editorState, setEditorState] = useState(EditorState.createEmpty())
  const [value, setValue] = useState('');
 
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [scroll, setScroll] = useState('paper');

  const [checked, setChecked] = useState(true);

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };


  const columns = useMemo(
    //column definitions...
    () => [
      {
        accessorKey: '_id',
        header: '_ID',
        size: 50, //small column
      },
      {
        accessorKey: 'descrizione',
        header: 'Descrizione',
        grow: true, //allow this column to grow to fill in remaining space - new in v2.8
        size: 300, //large column
      },
      {
        accessorKey: 'tipo_pratica',
        header: 'Tipo',
        minSize: 100, //min size enforced during resizing
        maxSize: 400, //max size enforced during resizing
        size: 180, //medium column
      },
      {
        accessorKey: 'giorni_validi_per_caricamento',
        header: 'Validità',
        minSize: 100, //min size enforced during resizing
        maxSize: 400, //max size enforced during resizing
        size: 180, //medium column
      },      
      {

        accessorFn: (row) => row.active ? "si" : "no", //accessorFn used to join multiple data into a single cell
        id: 'active', //id is still required when using accessorFn instead of accessorKey
        header: 'Attiva?',
        maxSize: 10,

      },
      
    ],
    [],
    //end
  );

  const handleSubmit = e => {
    e.preventDefault()
    console.log("_id", e.target[0].value)
    console.log("tipo_pratica", e.target[1].value)
    console.log("value", value);
    console.log("?", e.target[2].value)
    console.log("3", e.target[3].value)

    Array.prototype.forEach.call(e.target.elements, (element) => {
      console.log(element.type);
    })
}


const RenderTextEditor = (testo) => {
  // Editor ref
  const quill = useRef();

  return (
    <div >
    
    <ReactQuill ref={(el) => (quill.current = el)} theme="snow" defaultValue={testo} onChange={setValue} />
</div>
  )
}

const RenderSwitch = (value) => {

  let valueSwitch = false;
  if (value === true) {
    valueSwitch = true;
  }

  return (
     
      <FormControlLabel
        control={<Switch size="small" defaultChecked={valueSwitch} onChange={handleChange} />}
        label={`${checked? "Attiva" : "Non attiva"}`}
        color="warning"
        name='activeSwitch'
        id='activeSwitch'
      />
  )

}


const RemoveCheckedModulo = (e) => {

console.log(e)
}

const RinnovaTabella = () => {

  return handleRefetch();
}

const CreateWithMaterialUI = ({ open, rows, columns, onClose, ...props }) => {

    const [wisyText, setWisyText] = useState('');
  //  const { setFieldValue } = useFormikContext();
  const quill = useRef();
  /*  const handleChange = (option) => {
       setFieldValue(props.name, option);
   }; */
   const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'left',
    color: theme.palette.text.secondary,
  }));

   const [dataMenu, setDataMenu] = useState([]);
   const [primoModuloMenu, setPrimoModuloMenu] = useState([]);

    useEffect(() => {
        const doFetch = async () => {
           // const response = await fetch(`https://sgp.lambdamedia.it:5001/tipopratica/tipopratica_menu`);
            const response = await fetch(`https://sgp.lambdamedia.it:5001/tipopratica/tipopratica_box`);
            const tipopraticaMenu = await response.json();
            setDataMenu(tipopraticaMenu);         
            /*
            tipopraticaMenu.forEach(element => {
              arrayMenu.push(element._id)
            });          
            console.log(arrayMenu);
            setDataMenu(arrayMenu);        
            */
            };
        
        doFetch();

        }, []);

    useEffect(() => {
          const doFetch = async () => {
             // const response = await fetch(`https://sgp.lambdamedia.it:5001/tipopratica/tipopratica_menu`);
              const response = await fetch(`https://sgp.lambdamedia.it:5001/admin/moduli/`);
              const primoModuloMenu = await response.json();
              setPrimoModuloMenu(primoModuloMenu);         
              /*
              tipopraticaMenu.forEach(element => {
                arrayMenu.push(element._id)
              });          
              console.log(arrayMenu);
              setDataMenu(arrayMenu);        
              */
              };
          
          doFetch();
  
        }, []);

  const formik = useFormik({
    initialValues: {
      descrizione: '',
   //   giorni_validi_per_caricamento: '',
    //  tipo_pratica:'',
      modulo: '',
      documenti_necessari : '',
      tipopratica_menu: '',
    },
    
    value:{documenti_necessari:wisyText},
    validationSchema: validationSchema,
    onSubmit: (values) => {
      alert(JSON.stringify(values, null, 2));

        axios.get(`${REACT_APP_API_ENDPOINT}/api/tipopratica/tipologia/${values.tipopratica_menu}`)
        .then(function (response) {
          
       //console.log(response);
          alert(JSON.stringify(response.data[0].tipo));
          submitToServer(response.data[0].tipo);
          onClose();

        })
        .catch(function (error) {
          alert(error);
          return;
        });
      


      function submitToServer(value){

        let dataToSubmit = {
       
          descrizione: values.descrizione,
          tipo_pratica: value,
          scadenza: "0",
          modulo: [values.modulo],
          documenti_necessari: values.documenti_necessari,
          ordine: value,
          giorni_validi_per_caricamento: "7",
          active: true,
          created_at: new Date().toLocaleString(), 
          updated_at: new Date().toLocaleString(), 
        }

        axios.post(`${REACT_APP_API_ENDPOINT}/api/admin/tipopratica/create/`, dataToSubmit)
        .then(function (response) {
          
       //   console.log(response);
          alert(JSON.stringify(response.data));
          RinnovaTabella();
         // event.target.reset();
        })
        .catch(function (error) {
          alert(error);
  
        });

      }

    //  getDescrizioneByOrdine()

    },
  });


  return (
    <Dialog
    open={open}
    onClose={onClose}
    fullWidth={true}
    scroll={scroll}
  >
    <DialogTitle>Crea nuovo tipo pratica</DialogTitle>
    <DialogContent>
    <div>
    <FormikProvider value={formik}>
      <form onSubmit={formik.handleSubmit}>
      <Box  sx={{ flexGrow: 1, p: 2 }}>

      <Grid  container spacing={1}>

      <Grid item  xs={12} sm={12} md={12}>
      <TextField
      fullWidth
      variant="outlined"
      name="tipopratica_menu"
      id="tipologia"
      select
      label="Tipologia"
      value={formik.values.tipopratica_menu}
      //sx={{overflowY: "scroll"}}
      onChange={formik.handleChange}
      error={
        formik.touched.tipopratica_menu &&
        Boolean(formik.errors.tipopratica_menu)
      }
      helperText={
        formik.touched.tipopratica_menu && formik.errors.tipopratica_menu
      }
    >
       <MenuItem key={""} value={""}>
          No Selected // Or Empty
        </MenuItem>
      {dataMenu.map((option) => (
        <MenuItem key={option.ordine} value={option.ordine}>
        <Grid container spacing={1}>
          <Grid item xs={10}>
            <Item>{option.tipo}</Item>
          </Grid>
          <Grid item xs={2}>
            <Item>{option.ordine}</Item>
          </Grid>
        </Grid>   
        </MenuItem>
      ))}
    </TextField>
    </Grid> 




          <Grid item  xs={12} sm={12} md={12}>
          <TextField
          fullWidth
          id="descrizione"
          name="descrizione"
          label="Descrizione Pratica"
          value={formik.values.descrizione}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.descrizione && Boolean(formik.errors.descrizione)}
          helperText={formik.touched.descrizione && formik.errors.descrizione}
        />
      </Grid>

      <Grid item  xs={12} sm={12} md={12}>
      <TextField
      fullWidth
      variant="outlined"
      name="modulo"
      id="modulo"
      select
      label="Modulo"
      value={formik.values.modulo}
      //sx={{overflowY: "scroll"}}
      onChange={formik.handleChange}
      error={
        formik.touched.modulo &&
        Boolean(formik.errors.modulo)
      }
      helperText={
        formik.touched.modulo && formik.errors.modulo
      }
    >
       <MenuItem key={""} value={""}>
          No Selected // Or Empty
        </MenuItem>
      {primoModuloMenu.map((option) => (
        <MenuItem key={option._id} value={option._id}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Item>{option.tipo}</Item>
          </Grid>
        </Grid>   
        </MenuItem>
      ))}
    </TextField>
    </Grid> 

          <Grid item  xs={12} sm={12} md={12}>
          <div>{"Documenti necessari"}</div>
          <Field name="documenti_necessari" label="Documenti necessari">
          {({ field }) => <ReactQuill 
          ref={(el) => (quill.current = el)} value={field.value} onChange={field.onChange(field.name)} />}
          </Field>
       
                   
        </Grid> 
        {wisyText}

      </Grid> 

      
      </Box>

        <DialogActions>
        <Button onClick={onClose}>Annulla</Button>
        <Button type="submit">Crea nuova</Button>
      </DialogActions>
      </form>
      </FormikProvider>
    </div>
    </DialogContent>
{/* 
    <DialogActions>
      <Button onClick={onClose}>Cancel</Button>
      <Button type="submit">Subscribe</Button>
    </DialogActions>
*/}
  </Dialog>
  );
};

//DELETE action

const openDeleteConfirmModal = (row) => {

  if (window.confirm('Are you sure you want to delete this user?')) {

  //  deleteUser(row.original.id);

  }

};

/*
const handleCreateNewRow = (values) => {
    tableData.push(values);
    setTableData([...tableData]);
  };
*/

  const table = useMaterialReactTable({
    columns,
    data,
    enableExpanding: true,
    enableExpandAll: false, //disable expand all button
    enableRowActions:true,
    defaultColumn: {
      minSize: 20, //allow columns to get smaller than default
      maxSize: 9001, //allow columns to get larger than default
      size: 260, //make columns wider by default
    },
    positionActionsColumn: 'last',
    muiDetailPanelProps: () => ({
      sx: (theme) => ({
        backgroundColor:
          theme.palette.mode === 'dark'
            ? 'rgba(255,210,244,0.1)'
            : 'rgba(0,0,0,0.1)',
      }),
    }),
    //custom expand button rotation
    muiExpandButtonProps: ({ row, table }) => ({
      onClick: () => table.setExpanded({ [row.id]: !row.getIsExpanded() }), //only 1 detail panel open at a time
      sx: {
        transform: row.getIsExpanded() ? 'rotate(180deg)' : 'rotate(-90deg)',
        transition: 'transform 0.2s',
      },
    }),
    renderRowActions: ({ row, table }) => (

      <Box sx={{ display: 'flex', gap: '1rem' }}>


        <Tooltip title="Elimina">

          <IconButton color="error" onClick={() => openDeleteConfirmModal(row)}>

            <DeleteIcon />

          </IconButton>

        </Tooltip>

      </Box>

    ),
    /*
    renderRowActionMenuItems: ({ row }) => [
      <Box sx={{ display: 'flex', gap: '1rem' }}>

      <Tooltip title="Delete">

        <IconButton color="error" onClick={() => openDeleteConfirmModal(row)}>

          <DeleteIcon />

        </IconButton>

      </Tooltip>

    </Box>,
    ],
    */
    //conditionally render detail panel
    renderDetailPanel: ({ row }) =>
 
      row.original._id ? (
        <Container>
        <Box 
        sx={{ flexGrow: 1 }}
        >

      
        <form onSubmit={handleSubmit}>
        <Grid container spacing={2}>
        <Grid item xs={12}>
        <FormControl fullWidth>
           
            <TextField
            variant="outlined"
            label="_id"
            name="_id"
            defaultValue={row.original._id}
            disabled
           // onChange={onChange}
            />
        </FormControl>
         </Grid>
         <Grid item xs={6}>
         <FormControl fullWidth>
        
         <TextField
         variant="outlined"
         label="tipo_pratica"
         name="tipo_pratica"
         defaultValue={row.original.tipo_pratica}
        // onChange={onChange}
         />          
         </FormControl>
         </Grid>

         <Grid item xs={6}>
            <FormControl fullWidth>
           
            <TextField
            variant="outlined"
            label="descrizione"
            name="idescrizione"
            defaultValue={row.original.descrizione}
           // onChange={onChange}
            />       
            </FormControl>
        </Grid>

        <Grid item xs={12}>
        {RenderTextEditor(row.original.documenti_necessari)}
        </Grid>

        {/* 
        <Grid item xs={12}>
        <Alert variant="filled" severity="success">
            {row.original.documenti_necessari}
        </Alert>
       </Grid>
*/}

       <Grid item xs={12}>
       <FormControl fullWidth>
       <TableContainer component={Paper}>
       <Table sx={{background: 'none'}}>

       <TableHead>
       <TableRow>
         <TableCell>_id</TableCell>
         <TableCell align="right">Tipo</TableCell>
         <TableCell align="right">Contenuto</TableCell>
         <TableCell align="right">Documenti</TableCell>
         <TableCell align="right"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-trash3" viewBox="0 0 16 16">
         <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5ZM11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0H11Zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5h9.916Zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5Z"/>
       </svg></TableCell>
       </TableRow>
     </TableHead>

     <TableBody>
          {row.original?.moduls.map((row, idx) => (
            <TableRow
              key={row._id}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">{row._id}</TableCell>
              <TableCell align="right">{row.tipo}</TableCell>
              <TableCell align="right">{row.contenuto}</TableCell>
              <TableCell align="right">{row.documento}</TableCell>
              <TableCell align="right"><input className="form-check-input" type="checkbox" value={idx} onChange={()=>RemoveCheckedModulo(idx)} id={idx} /></TableCell>
            </TableRow>
          ))}
        </TableBody>
        </Table>
    </TableContainer>
       
       </FormControl>
       </Grid>

       <Grid item xs={6}>
       <FormControl fullWidth>
       <TextField
       variant="outlined"
       label="giorni_validi_per_caricamento"
       name="giorni_validi_per_caricamento"
       defaultValue={row.original.giorni_validi_per_caricamento}
      // onChange={onChange}
       />
       
       </FormControl>
       </Grid>


{/* 
      <Grid item xs={6}>
       <FormControl fullWidth>           
        <TextField
          variant="outlined"
          label="active"
          name="active"
          defaultValue={row.original.active}
           // onChange={onChange}
        />    
      </FormControl>
      </Grid>
*/}
   <Grid item xs={6}>
      <FormControl fullWidth>
        
     <FormLabel component="legend">Stato {row.original.descrizione}</FormLabel>

     {RenderSwitch(row.original.active)}
           
      </FormControl>
      
  </Grid>


          {/*   
                  {
        "_id": "618981a4c1b8b3bc67ff80bc",
        "descrizione": "Supplementare",
        "scadenza": "0",
        "giorni_validi_per_caricamento": "7",
        "ordine": "2",
        "active": "1",
        "created_at": null,
        "updated_at": null,
        "tipo_pratica": "Pensioni",
        "documenti_necessari": "<p><strong>DOCUMENTI NECESSARI:</strong></p><ol type=\"1\"><li>Documento di riconoscimento e codice fiscale, del Richiedente e del Coniuge.</li><li>Divorziato/Separato - Sentenza di divorzio o separazione.</li><li>Iban e dati banca o posta (se posta indicare il frazionario), indicare se cointestato.</li><li>Indicazione di eventuali pensioni già in godimento; numero, categoria, decorrenza ed ammontare lordo annuo.</li><li>Data cessazione rapporto di lavoro supplementare.</li><li>Estratto contributivo o gestione di cui si chiede la pensione supplementare.</li></ol>",
        "modulo": [
            "628015cd2fd9dfee86ac6820",
            "62801a4c2fd9dfee86ac6821",
            "628a5768292f80bee7ca53a6"
        ],
        "moduls": [
            {
                "_id": "628015cd2fd9dfee86ac6820",
                "tipo": "Mandato di assistenza e rappresentanza",
                "messaggio": "no o si?",
                "documento": [
                    "1714668010910_Pannello_loghi_copia.pdf"
                ],
                "contenuto": "no contenuto 8889"
            },
            {
                "_id": "62801a4c2fd9dfee86ac6821",
                "tipo": "Modello red da far compilare al richiedente ed all’eventuale coniuge per ciascun anno interessato, non inserire i redditi da pensione INPS. ",
                "messaggio": "Ulteriore testo",
                "documento": [
                    "1714912547506_VetroFania_copia.pdf"
                ],
                "contenuto": "lexicom"
            }
        ]
    },
              
                <div>
                    <Button
                        type="submit"
                        text="Submit" 
                        />
                    <Button
                        text="Reset"
                        color="default"
                       // onClick={resetForm} 
                       />
                </div>
            </Grid>

*/} 

        </Grid>
        
        <Stack spacing={2} direction="row" style={{float: 'right'}}>
        <Button type="submit" variant="outlined">Salva modifiche</Button>
    </Stack>
</form>
    </Box>
    </Container>
      ) : null,

    renderTopToolbarCustomActions: ({ table, row }) => (


      <>
      <Button
      onClick={() => setCreateModalOpen(true)}
    >
    
      Aggiungi Pratica
  </Button> 


    <CreateWithMaterialUI
        rows={row}
        columns={columns}
        open={createModalOpen}
        onClose={() => setCreateModalOpen(false)}
       // onSubmit={handleCreateNewRow} 
       />
 
       </>

    ),




  });

  return <MaterialReactTable table={table} />;
};

export default TipoPraticheTableHeader;
