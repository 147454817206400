import {
    MaterialReactTable,
    useMaterialReactTable,
  } from 'material-react-table';
  import { useMemo, useState, useEffect } from 'react';
  //import TableAttiveContenitore from './TableAttiveContenitore';
  import { Box, Typography } from '@mui/material';
  import AddIcon from '@mui/icons-material/Add';
  import MinusIcon from '@mui/icons-material/Remove';
  import Endpoints from '../../../Component/services/Endpoints';
  //import FileUpload from '../../File_Upload_Component/file-upload.component'
  import socketIOClient from "socket.io-client";
  import axios from "axios";
  //Material UI Imports
  import CircularProgress from '@mui/material/CircularProgress';
import {
    MenuItem,
  } from '@mui/material';
    //Icons Imports
  const { REACT_APP_API_ENDPOINT } = process.env;
   
  const TableAnnullateContenitoreCons = ({ data, columns, renderRowSubComponent, renewTable }) => {
   
   const [spinna, setSpinna] = useState(false);
function notificaAlConsulente(data) {
     
       const notifyObj = {
       emailto: data.consulente.email,
       idpratica: data._id,
       descrizione: data.descrizione,
       stato: data.statoPraticax,
       assistito: `${data.assistito.nome} ${data.assistito.cognome}`,
       operatore: `${data.operatore[data.operatore.length - 1].nome} ${data.operatore[data.operatore.length - 1].nome}`,
       contatti: `${data.operatore[data.operatore.length - 1].email}`
       }
   
       /*
       //notifico con mail al consulente
       axios.post(`${REACT_APP_API_ENDPOINT}/api/consulente/notifica/action`, notifyObj)
       .then(function (response) {
           console.log(response.data);
       })
       .catch(function (error) {
           console.log(error);
       });
       */
   
   }

 
  const table = useMaterialReactTable({
    columns,
    data,
    enableExpandAll: false, //disable expand all button
    enableExpanding: true,
    enableRowActions: false,
    initialState: { 
      showColumnFilters: true,
      columnVisibility: { _id: false } //hide _id column by default
     // pagination: { pageSize: 25, pageIndex: 2 }
  },
    muiDetailPanelProps: () => ({
  
      sx: (theme) => ({
  
        backgroundColor:
  
          theme.palette.mode === 'dark'
  
            ? 'rgba(255,210,244,0.1)'
  
            : 'rgba(0,0,0,0.1)',
  
      }),
  
    }),
  
       //custom expand button rotation
       muiExpandButtonProps: ({ row, table }) => ({
        children: row.getIsExpanded() ? <MinusIcon sx={{color:'red'}}/> : <AddIcon />,
        onClick: () => table.setExpanded({ [row.id]: !row.getIsExpanded() }), //only 1 detail panel open at a time
        sx: {
          transform: row.getIsExpanded() ? 'rotate(180deg)' : 'rotate(-90deg)',
          transition: 'transform 0.2s',
        },
  
      }),
  /*
      renderDetailPanel: ({ row }) => (
        
        <AttiveSub row={row} praticaId={row.original._id}/>
  
      ),
   */   
      renderDetailPanel: ({ row }) => renderRowSubComponent( row ),

       renderRowActionMenuItems: ({ row }) => [
        <MenuItem key="1" onClick={() => {handleAssignment({ row }); setSpinna(true)}} >
        Prendi
      </MenuItem>,
      <MenuItem key="2" >
      <Spinner />
    </MenuItem>
      ],



  });

  const Spinner = () => {
    if (spinna) {
        return <CircularProgress />
    }  return null;
    
  }
 

  const handleContact = ({row}) => {
    alert('contact ' + row.original._id);
    renewTable(row.original._id);
  //  closeMenu();
    /*
    table.getSelectedRowModel().flatRows.map((row) => {
      alert('contact ' + row.getValue('_id'));
    });
*/
  };
//qui mi assegno la pratica
const handleAssignment = ({row}) => {

    console.log(row.original._id);

    var idPratica = row.original._id;
    let decrizionePratica = row.original.descrizione;
    let consulente = row.original.Consulente;
    let consulenteMail = row.original.consulente.email;
    let assistito = row.original.Assistito;
    const operatoreClient = JSON.parse(localStorage.getItem('currentUser'));
    // const {_id, cognome, nome, email, role} = operatoreClient;
    const {_id} = operatoreClient;

    // return
    // fetch(`${REACT_APP_API_ENDPOINT}/api/users/id?_id=${_id}`)
    fetch(`${REACT_APP_API_ENDPOINT}/api/users/${_id}`)
        .then((res) => res.json())
        // .then((data) => setData({...data}))
        .then((res) => {
            //  setOperatoreAttivo(res);



            const operatoreAct = res;
            // qui modifico la pratica
            // fetch(`${REACT_APP_API_ENDPOINT}/api/pratiche/assegna/operatore:id?_id=${idPratica}`, {
            fetch(`${REACT_APP_API_ENDPOINT}/api/pratiche/assegna/operatore/id?_id=${idPratica}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(operatoreAct)
            })
                .then(res => res.json())
                .then(data => {


renewTable(row.original._id);
setSpinna(false);
                    const operatoreAttivo = JSON.parse(localStorage.getItem('currentUser'));
                    const {_id, cognome, nome, email, role} = operatoreAttivo;
                    if (data) {
                        let notifyObj = {
                            emailto: consulenteMail,
                            idpratica: idPratica,
                            descrizione: decrizionePratica,
                            stato: 'Assegnata',
                            assistito: assistito,
                            operatore: `${operatoreClient.cognome} ${operatoreClient.nome}`,
                            contatti: `Cell: ${operatoreClient.cellulare} - Tel: ${operatoreClient.telefono} Email: ${operatoreClient.email}`
                        };
                        //       notificaAlConsulente(notifyObj);
                    }
                    let assegnaObj = {
                        _id: _id,
                        cognome: cognome,
                        nome: nome,
                        email: email,
                        role: role,
                        date: new Date().toLocaleString('it-IT'),
                        consulente: consulente,
                        assistito: assistito,
                        praticaId: idPratica,
                        descrizione: decrizionePratica
                    }
                    const socket = socketIOClient(`${REACT_APP_API_ENDPOINT}/api/`);
                    socket.emit('assegnazione', JSON.stringify(assegnaObj));
                    //  console.log("data? ",data)
                })
                .catch(error => {
                    // enter your logic for when there is an error (ex. error toast)
                    console.log(error)
                });

        });

}
  //DELETE action

  
    return <MaterialReactTable table={table} />;
  
  };
  
  export default TableAnnullateContenitoreCons;
  