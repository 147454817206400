import React, {useState, useEffect, useMemo} from 'react';
import axios from "axios";

import Swal from 'sweetalert2';
import socketIOClient from "socket.io-client";
import FileUpload from '../../../File_Upload_Component/file-upload.component';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import Button from '@mui/material/Button';
import SendIcon from '@mui/icons-material/Send';
import { styled } from '@mui/system';
import TableUsersAdminContenitore from './TableUsersAdminContenitore';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text,
}));

const {REACT_APP_API_ENDPOINT} = process.env;


const UsersAdminSub = ({row}) => {

    const [data, setData] = useState([])
    const [NewStatoPratica, setNewStatoPratica] = useState('');
    const [isClosedShow, setIsClosedShow] = useState(false);
    const [isNotClosedShow, setIsNotClosedShow] = useState(false);
    const [showUploader, setShowUploader] = useState(false);
   
    const [selectedFiles, setSelectedFiles] = useState([]);

    //FileInfos anche se da warning che non è usato non è vero
    const [fileInfos, setFileInfos] = useState([]);
    const [progress, setProgress] = useState(0);
    const [ricevuta, setRicevuta] = useState([]);

    const [notex, setNotex] = useState('');
    const [selectedIdPratica, setSelectedIdPratica] = useState('');

    const [isVisible, setIsVisible] = useState(true);
    const [newRicevutaInfo, setNewRicevutaInfo] = useState({
        ricevutaFile: []
    });


     //data and fetching state
    const [isError, setIsError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isRefetching, setIsRefetching] = useState(false);
    const [rowCount, setRowCount] = useState(0);
    
    //from old component
    const [currentLoggedUser, setCurrentLoggedUser] = useState({});
    //table state
    const [columnFilters, setColumnFilters] = useState([]);
    const [globalFilter, setGlobalFilter] = useState('');
    const [sorting, setSorting] = useState([]);
    const [pagination, setPagination] = useState({
      pageIndex: 0,
      pageSize: 10,
    });

    const [openDialog, setOpenDialog] = useState(false);
  //  const [operatoreAttivo, setOperatoreAttivo] = useState(false);

    const operatoreAttivo = JSON.parse(localStorage.getItem('currentUser'));
  // const {_id, cognome, nome, email, role} = operatoreClient;
  //   setOperatoreAttivo(operatoreClient);

  function setColumnFiltersFunc(val){
    setColumnFilters(val);
   }
   function setGlobalFilterFunc(val){
    setGlobalFilter(val);
   }
   function setSortingFunc(val){
    setSorting(val);
   }

   function setPaginationFunc(val){
    setPagination(val);
   }

  // fetch pratiche in lavorazione by operatore id
  //if you want to avoid useEffect, look at the React Query example instead
  useEffect(() => {
       
           const fetchData = async () => {
             if (!data.length) {
               setIsLoading(true);
             } else {
               setIsRefetching(true);
             }
           
           const op = JSON.parse(localStorage.getItem('currentUser'));
           const id = op._id;
           setCurrentLoggedUser(op);
       
            const url = new URL(`${REACT_APP_API_ENDPOINT}/api/users/`,
               process.env.NODE_ENV === 'production'
                 ? 'https://www.material-react-table.com'
                 : 'http://localhost:3000',
             );
             url.searchParams.set(
               'start',
               `${pagination.pageIndex * pagination.pageSize}`,
             );
             url.searchParams.set('size', `${pagination.pageSize}`);
             url.searchParams.set('filters', JSON.stringify(columnFilters ?? []));
             url.searchParams.set('globalFilter', globalFilter ?? '');
             url.searchParams.set('sorting', JSON.stringify(sorting ?? []));
       
             try {
               const response = await fetch(url.href);
               const json = await response.json();
               setData(json);
               setRowCount(response.headers.get('X-Total-Count'));
             } catch (error) {
               setIsError(true);
               console.error(error);
               return;
             }
             setIsError(false);
             setIsLoading(false);
             setIsRefetching(false);
           };
           fetchData();
           // eslint-disable-next-line react-hooks/exhaustive-deps
         }, [
           columnFilters,
           globalFilter,
           pagination.pageIndex,
           pagination.pageSize,
           sorting,
  ]);

  //qui mi assegno la pratica
const handleShow = (event) => {

  console.log(event.cell.row.values._id);
  var idPratica = event.cell.row.values._id;
  const descrConfirm = event.cell.row.values.descrizione;
  const aConfirmNome = event.cell.row.original.assistito.nome;
  const aConfirmCognome = event.cell.row.original.assistito.cognome;

  const cConfirmNome = event.cell.row.original.consulente.nome;
  const cConfirmCognome = event.cell.row.original.consulente.cognome;

  const oConfirmNome = event.cell.row.original.operatore[0].nome;
  const oConfirmCognome = event.cell.row.original.operatore[0].cognome;

  const adminClient =JSON.parse(localStorage.getItem('currentUser'));

if(adminClient.role[0] === 'ROLE_ADMIN'){
  Swal.fire({
    title: 'Vuoi davvero eliminare?',
    text: `${descrConfirm}`,
    icon: 'warning',
    html: `<b>Descrizione: </b>${descrConfirm}<br><b>Consulente: </b>${cConfirmCognome} ${cConfirmNome} <br> <b> Assistito: </b> ${aConfirmCognome} ${aConfirmNome}` ,
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: 'SI, elimina!',
    footer: `<b>Operatore: <b/>${oConfirmCognome} ${oConfirmNome}`,
  }).then((result) => {
    if (result.isConfirmed) {
//inizio conferma seleziona
   // qui elimino la pratica
    fetch(`${REACT_APP_API_ENDPOINT}/api/pratica/eliminazione/action/id?_id=${idPratica}`, {
      headers: {
        'Content-Type': 'application/json',
      },
     
    })
      .then(res => res.json())
      .then(data => {

        if(data.success){

       //   renewTable();
          Swal.fire(
            'Eliminita!',
            data.success,
            'Grazie'
          )

       }
       if(data.error){
        Swal.fire(
          'Error!',
          data.error,
          'Grazie'
        )
          }

      })
      .catch(error => {
      // enter your logic for when there is an error (ex. error toast)
      console.log(error)
      });

      //fine conferma seleziona
    }
  })

} else {
  Swal.fire(
    'Non hai i privilegi per eseguire questa azione',
    'Grazie'
  )
 
}

}

  function notificaAlConsulente(obj){
    //notifico con mail al consulente
    axios.post(`${REACT_APP_API_ENDPOINT}/api/consulente/notifica/action`, obj)
    .then(function (response) {
      console.log(response.data);
    })
    .catch(function (error) {
      console.log(error);
    });
}

const renewTableAfterDelete = (val) => {
  setData((data) => data.filter(({_id}) => _id !== val.praticaId));

}

const renewTable = (val) =>{       
  setData((data) => data.filter(({_id}) => _id !== val));  
}


function praticaSelezionata( praticaID ) {
setSelectedIdPratica(praticaID)
console.log("praticaSelezionata", praticaID)
}
        
function deletePratica( row ) {
   
  var idPratica = row._id;
  const adminClient =JSON.parse(localStorage.getItem('currentUser'));

    if(adminClient.role[0] === 'ROLE_ADMIN'){
      Swal.fire({
        title: `Vuoi davvero eliminare la pratica n° ${row.pratica_numero}?`,
        icon: 'danger',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'SI, elimina!',
        footer: `<b>Admin<b/>`,
      }).then((result) => {
        if (result.isConfirmed) {
    //inizio conferma seleziona
      // qui elimino la pratica
        fetch(`${REACT_APP_API_ENDPOINT}/api/pratiche/eliminazione/action/id?_id=${idPratica}`, {
          headers: {
            'Content-Type': 'application/json',
          },
        
        })
      .then(res => res.json())
      .then(data => {
        if(data.success){

          let updTbl = {
            praticaId: idPratica
          }
       
          renewTableAfterDelete(updTbl);

          Swal.fire(
            'Eliminata!',
            'Un documento eliminato con successo.',
           // data.success,
            'Grazie'
          )

       }
       if(data.error){
        Swal.fire(
          'Error!',
          data.error,
          'Grazie'
        )
          }

      })
      .catch(error => {
      // enter your logic for when there is an error (ex. error toast)
      console.log(error)
      });

      //fine conferma seleziona
    }
  })

} else {
  Swal.fire(
    'Non hai i privilegi per eseguire questa azione',
    'Grazie'
  )
 
}
}



const columns = useMemo(
           () => [
             {
               accessorKey: '_id',
               header: '_id',
             },
             {
              accessorKey: 'userid',
              header: 'id',
              minSize: 20, //min size enforced during resizing
              maxSize: 80, //max size enforced during resizing
              size: 20, //medium column
            },
             //column definitions...
             {
               accessorKey: 'nome',
               header: 'Nome',
             },
             {
               accessorKey: 'cognome',
               header: 'Cognome',
             },
             {
              accessorKey: 'email',
              header: 'Email',
            },
            {
              accessorFn: (row) => (row.roles ? row.roles : "" ),
              id: 'roles',
              header: 'Ruolo',
             },
             {
              accessorKey: 'stato',
              header: 'Stato',
            },
            {
              accessorKey: 'creato',
              header: 'Creato',
            }, 
            {
              accessorFn: (row) => (row.indirizzo?.comune ? row.indirizzo.comune : "" ),
              id: 'indirizzo.comune',
              header: 'Comune',
            },         
             {
              accessorFn: (row) => (row.indirizzo?.provincia ? row.indirizzo.provincia : "" ),
              id: 'indirizzo.provincia',
              header: 'Provincia',
             },
           ],
           [],
);
    

    const renderRowSubComponent = (row) => {
 //   console.log(row.original)
 /*
[{
  "_id": {
    "$oid": "62929bc6c8362dc508d7519e"
  },
  "nome": "ADMIN",
  "cognome": "CAF",
  "codice_fiscale": "323213",
  "partita_iva": "12312323",
  "ragione_sociale": "CAF",
  "cellulare": "1232",
  "telefono": "33123",
  "consenso": "si",
  "creato": "Lun, 08/02/2021 - 17:35",
  "email": "studio.cristofari@libero.it",
  "password": "$2a$10$63UDzQJQXi6uGBoKAzsMeu.rY3nMnc6eSM.lOGZF/0cIjeODzzdOy",
  "indirizzo": {
    "via": "Asdas",
    "cap": "12312",
    "localita_esercizio": "Roma",
    "comune": "Roma",
    "provincia": "RM",
    "regione": "Lazio",
    "citta": "Roma"
  },
  "pec": "studio.cristofari@libero.it",
  "fax": "12323",
  "roles": [
    "admin"
  ],
  "collaboratore": {
    "nome": "Sandro",
    "cognome": "Sandro",
    "codice_fiscale": "PGGSDR67C24H501U"
  },
  "stato": "On",
  "ritratto": "",
  "ultimo_accesso": "8/9/2022, 11:13:36",
  "user_otp": "ZL8CU7 ",
  "userid": 15000,
  "documento": [],
  "mandato": []
}]
 */

    let {
      userid,
      nome,
      cognome,
      codice_fiscale,
      partita_iva,
      ragione_sociale,
      cellulare,
      telefono,
      consenso,
      creato,
      email,
      pec,
      fax,
      password,
      stato,
      ritratto,
      ultimo_accesso,
      user_otp,
        ...rest
    } = row.original;
    let indirizzo = rest.indirizzo;
    let roles = rest.roles;
    let documento = rest.documento;
    let mandato = rest.mandato;
    let collaboratore = rest.collaboratore;
    let user_id = rest._id;

    const renewTable = (val) => {
      setData((data) => data.filter(({_id}) => _id !== val.praticaId));
    }


    const checkRolesList = ["admin", "consulente", "operatore", "teleconsul", "namirial", "regionale"];

    const RenderRolesList = () => {
      return (
    <Box sx={{ display: 'flex', flexDirection: 'row' }}>
      <FormControl sx={{ m: 6 }} component="fieldset" variant="standard">
        <FormLabel component="legend">User responsibilità</FormLabel>
         {checkRolesList.map((item, index) => (
        <FormGroup key={index}>
         {findValue(item) ?  <FormControlLabel name={item} value={item} id={item}  control={<Checkbox defaultChecked={item ? true : false} />} label={item} disabled/>
          : <FormControlLabel name={item} value={item} id={item}  control={<Checkbox defaultChecked={!item} />} label={item} disabled/>}
          </FormGroup>

      ))}

     
      </FormControl>
      </Box>
      );
    }
  
  
    const findValue = (valore) => {
      //   const array1 = [5, 12, 8, 130, 44];  
           const found = roles.find(element => element === valore);
           if(found){
             return true;
           } 
    }


    const handleSubmit = (event) => {
        event.preventDefault();
       
        let aggiornaObj = {
         
           _id: selectedIdPratica,
            statoPratica: NewStatoPratica,
            ricevuta: ricevuta,
            note: notex,
            storico_pratica: {
                stato: NewStatoPratica,
                data: new Date().toLocaleString('it-IT', { timeZone: 'UTC' })
            }
        }
        console.log(aggiornaObj);

        const headers = {
            "Content-Type": "application/json",
        }

        axios.post(`${REACT_APP_API_ENDPOINT}/api/pratiche/cambiostato/`, aggiornaObj, {
            headers: headers,
        })
            .then(response => { // then print response status
                console.log(response);

                fetch(`${REACT_APP_API_ENDPOINT}/api/pratiche/inserita/id?_id=${aggiornaObj._id}`, {
                        headers: {
                            'Content-Type': 'application/json'
                            // 'Content-Type': 'application/x-www-form-urlencoded',
                        }
                    }
                ).then(response => response.json())
                    .then(data => notificaAlConsulente(data));


                Swal.fire({
                    title: 'Cambio stato',
                    text: `Hai cambiato questa pratica in: ${NewStatoPratica}`,
                    icon: 'info',
                    showCancelButton: false,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'OK!',
                    footer: `<b>Operatore<b/>`,
                }).then((result) => {
                    if (result.isConfirmed) {
                        const socket = socketIOClient(`${REACT_APP_API_ENDPOINT}/api/`);
                        socket.emit('cambiostato', JSON.stringify({pratica: 'aggiornata'}));
                        let updTbl = {
                            praticaId: selectedIdPratica
                        }

                        renewTable(updTbl);
                    } else {
                        const socket = socketIOClient(`${REACT_APP_API_ENDPOINT}/api/`);
                        socket.emit('cambiostato', JSON.stringify({pratica: 'aggiornata'}));
                        let updTbl = {
                            praticaId: selectedIdPratica
                        }

                        renewTable(updTbl);

                    }
                })


            })

        //fine
    }
    return (
        <>
        <Container maxWidth="lg">
          <Divider className={"container"} component="div" role="presentation"
            sx={{ marginTop: '20px', marginBottom: '20px' }}>
            {/* any elements nested inside the role="presentation" preserve their semantics. */}
            <Typography variant="h5">Consulente:</Typography>
          </Divider>
          <Grid container fixed="true" spacing={2}>
            <Grid item xs={4}>
              <Item> <small style={{ color: 'rgb(131 135 140)' }}>Cognome: </small>
                {`${cognome} `}</Item>
            </Grid>
            <Grid item xs={4}>
              <Item><small style={{ color: 'rgb(131 135 140)' }}>Nome: </small>
                {`${nome} `}</Item>
            </Grid>
            <Grid item xs={4}>
              <Item><small style={{ color: 'rgb(131 135 140)' }}> Email: </small>
                {`${email} `}</Item>
            </Grid>
            <Grid item xs={4}>
              <Item><small style={{ color: 'rgb(131 135 140)' }}> Cellulare: </small>
                {`${cellulare} `}</Item>
            </Grid>
            <Grid item xs={4}>
              <Item><small style={{ color: 'rgb(131 135 140)' }}> Telefono: </small>
                {`${telefono} `}</Item>
            </Grid>
            <Grid item xs={4}>
            <Item><small style={{ color: 'rgb(131 135 140)' }}> Ragione sociale: </small>
              {`${ragione_sociale} `}</Item>
          </Grid>
          <Grid item xs={4}>
          <Item><small style={{ color: 'rgb(131 135 140)' }}> PEC: </small>
            {`${pec} `}</Item>
        </Grid>
        <Grid item xs={4}>
        <Item><small style={{ color: 'rgb(131 135 140)' }}> Fax </small>
          {`${fax} `}</Item>
      </Grid>

      <Grid item xs={4}>
      <Item><small style={{ color: 'rgb(131 135 140)' }}> C.F. </small>
        {`${codice_fiscale} `}</Item>
    </Grid>
    <Grid item xs={4}>
    <Item><small style={{ color: 'rgb(131 135 140)' }}> P.IVA </small>
      {`${partita_iva} `}</Item>
  </Grid>
  <Grid item xs={4}>
  <Item><small style={{ color: 'rgb(131 135 140)' }}> Consenso </small>
    {`${consenso} `}</Item>
</Grid>
<Grid item xs={4}>
<Item><small style={{ color: 'rgb(131 135 140)' }}> Creato il </small>
  {`${creato} `}</Item>
</Grid>

<Grid item xs={4}>
<Item><small style={{ color: 'rgb(131 135 140)' }}> Via </small>
  {`${indirizzo?.via} `}</Item>
</Grid>
<Grid item xs={4}>
<Item><small style={{ color: 'rgb(131 135 140)' }}> Cap </small>
  {`${indirizzo?.cap} `}</Item>
</Grid>
<Grid item xs={4}>
<Item><small style={{ color: 'rgb(131 135 140)' }}> Esercizio in </small>
  {`${indirizzo?.localita_esercizio} `}</Item>
</Grid>
<Grid item xs={4}>
<Item><small style={{ color: 'rgb(131 135 140)' }}> Comune </small>
  {`${indirizzo?.comune} `}</Item>
</Grid>
<Grid item xs={4}>
<Item><small style={{ color: 'rgb(131 135 140)' }}> Prov. </small>
  {`${indirizzo?.provincia} `}</Item>
</Grid>
<Grid item xs={4}>
<Item><small style={{ color: 'rgb(131 135 140)' }}> Regione </small>
  {`${indirizzo?.regione} `}</Item>
</Grid>
<Grid item xs={4}>
<Item><small style={{ color: 'rgb(131 135 140)' }}> Stato </small>
  {`${indirizzo?.stato} `}</Item>
</Grid>
      
            <Grid item xs={4}>
              <Button variant="contained" endIcon={<SendIcon />} size="small" onClick={() => setOpenDialog(true)} >
                Invia Messaggio al consulente
              </Button>
            </Grid>
          </Grid>
        </Container>

        <Container>
        {RenderRolesList()}
        </Container>

        <Container maxWidth="lg">
          <Divider className={"container"} component="div" role="presentation"
            sx={{ marginTop: '20px', marginBottom: '20px' }}>
            {/* any elements nested inside the role="presentation" preserve their semantics. */}
            <Typography variant="h5">Collaboratore volontario:</Typography>
          </Divider>
          <Grid container fixed="true" spacing={2}>
            <Grid item xs={4}>
              <Item> <small style={{ color: 'rgb(131 135 140)' }}>Cognome: </small>
                {`${collaboratore?.cognome} `}</Item>
            </Grid>
            <Grid item xs={4}>
              <Item><small style={{ color: 'rgb(131 135 140)' }}>Nome: </small>
                {`${collaboratore?.nome} `}</Item>
            </Grid>
            <Grid item xs={4}>
              <Item><small style={{ color: 'rgb(131 135 140)' }}>C.F: </small>
                {`${collaboratore?.codice_fiscale} `}</Item>
            </Grid>
          </Grid>
        </Container>
        {/* 
        <Container maxWidth="lg">
          <Divider className={"container"} component="div" role="presentation"
            sx={{ marginTop: '20px', marginBottom: '20px' }}>
            <Typography variant="h5">Cambia lo stato pratica qui:</Typography>
          </Divider>
          <Grid container fixed="true" spacing={2}>
            <Grid item xs={12} component="div">
              <Item component="div">
                <form onSubmit={handleSubmit} encType="multipart/form-data">
                  <select defaultValue={statoPratica}
                    className="form-select form-select-lg mb-3"
                    aria-label="Default select example"
                    onChange={(event) => {
                      handleSelect(event);
                      setSelectedIdPratica(pratica_id);
                    }}
                  >
                    {statiPossibili.map((item, i) => {
                      return <option key={i} value={item}>{item}</option>
                    })}
                  </select>
                  <p />
                  {showUploader ? RenderNonClosedCase2() : null}
                </form>
              </Item>
            </Grid>

          </Grid>
        </Container>
*/}

        </>


    );
}

    return (
        <TableUsersAdminContenitore 
        data={data} 
        columns={columns} 
        renderRowSubComponent={renderRowSubComponent} 
        isLoading={isLoading} 
        isError={isError}
        renewTable={renewTable} 
        praticaSelezionata={praticaSelezionata} 

        deletePratica={deletePratica} 
        isRefetching={isRefetching} 
        setColumnFiltersFunc={setColumnFiltersFunc} 
        setGlobalFilterFunc={setGlobalFilterFunc} 
        setSortingFunc={setSortingFunc} 
        setPaginationFunc={setPaginationFunc} 
        rowCount={rowCount}
        />


    );
}

export default UsersAdminSub;