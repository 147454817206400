import { useMemo, useState } from 'react';
import {
  MaterialReactTable,
  useMaterialReactTable,
  MRT_EditActionButtons,
  createRow,
} from 'material-react-table';
//Import Material React Table Translations
import FormElements from "./FormElements";
import { MRT_Localization_IT } from 'material-react-table/locales/it';
//import TableAttiveContenitore from './TableAttiveContenitore';
import AddIcon from '@mui/icons-material/Add';
import MinusIcon from '@mui/icons-material/Remove';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import Switch from '@mui/material/Switch';
import { pink } from '@mui/material/colors';
import FormLabel from "@mui/material/FormLabel";
import Modal from '@mui/material/Modal';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Tooltip,
  Dialog,
  Stack,
  TextField,
} from '@mui/material';
//import FileUpload from '../../File_Upload_Component/file-upload.component'
import { Delete as DeleteIcon }from '@mui/icons-material';

const TableUsersAdminContenitore = ({
  data, 
  columns, 
  renderRowSubComponent, 
  isLoading,
  isError, 
  isRefetching,
  deletePratica,
  handleAssegna,
  renewTable,
  praticaSelezionata,
  setColumnFiltersFunc, 
  setGlobalFilterFunc, 
  setSortingFunc, 
  setPaginationFunc, 
  rowCount,
}) => {
 
    const [createModalOpen, setCreateModalOpen] = useState(false);
    const [scroll, setScroll] = useState('paper');

    const [rolesName, setRolesName] = useState([]);

    const [checkRolesName, setCheckRolesName] = useState([]);

    const [checked, setChecked] = useState(true);

    const [unchecked, setUnchecked] = useState(false);

    const [nome, setNome] = useState('');

      const handleChangeActiveUser = (event) => {
        setChecked(event.target.checked);
      };

  const handleChangeRoles = (event) => {
    const {
      target: { value },
    } = event;
    setRolesName(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
  };

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleChangeCheckRoles = (event) => {
    const {
      target: { name },
    } = event;
 //   setFilteredProducts([...products, ...productsToAdd]);
    setCheckRolesName(
      // On autofill we get a stringified value.

      typeof name === 'string' ? [...checkRolesName, ...name.split(',')] : name.split(','),
      console.log(checkRolesName)
    );
  };



    const handleClickOpen = (scrollType) => () => {
           setScroll(scrollType);
    };

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };


  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 800,
    bgcolor: 'background.paper',
    border: '1px solid #000',
    overflow:'scroll',
    boxShadow: 24,
    p: 4,
  };

   function notificaAlConsulente(data) {
   
     const notifyObj = {
     emailto: data.consulente.email,
     idpratica: data._id,
     descrizione: data.descrizione,
     stato: data.statoPraticax,
     assistito: `${data.assistito.nome} ${data.assistito.cognome}`,
     operatore: `${data.operatore[data.operatore.length - 1].nome} ${data.operatore[data.operatore.length - 1].nome}`,
     contatti: `${data.operatore[data.operatore.length - 1].email}`
     }
 
     /*
     //notifico con mail al consulente
     axios.post(`${REACT_APP_API_ENDPOINT}/api/consulente/notifica/action`, notifyObj)
     .then(function (response) {
         console.log(response.data);
     })
     .catch(function (error) {
         console.log(error);
     });
     */
 
 }

 const handleCreateNewRow = (values) => {

//  tableData.push(values);

 // setTableData([...tableData]);

};

const table = useMaterialReactTable({
  columns,
  data,
  enableEditing: true,
  createDisplayMode: 'modal',
  positionCreatingRow: 'top', //default is 'top'
  enableExpandAll: false, //disable expand all button
  enableExpanding: true,
  enableRowActions: true,
  localization: MRT_Localization_IT,
  initialState: { 
    showColumnFilters: true,
    columnVisibility: { _id: false } //hide _id column by default
   // pagination: { pageSize: 25, pageIndex: 2 }
  },
  manualFiltering: false, //turn off built-in client-side filtering
  manualPagination: false, //turn off built-in client-side pagination
  
  state: {
    isLoading,
    showAlertBanner: isError,
    showProgressBars: isRefetching,
  },
  // onColumnFiltersChange:()=> setColumnFiltersFunc,
  // onGlobalFilterChange: ()=> setGlobalFilterFunc,
  // onPaginationChange: ()=> setPaginationFunc,
  // onSortingChange: ()=> setSortingFunc,
  rowCount,
  positionToolbarAlertBanner: 'bottom',
  positionActionsColumn: 'last',

  //optionally customize modal content

  renderCreateRowDialogContent: ({ table, row, internalEditComponents, classes }) => (
    
    <>
    {BasicModal()}
    </>
    
    /*
    <>
      <DialogTitle variant="h3">Create New User</DialogTitle>
      <DialogContent
        sx={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}
      >
      {CreateNewAccountModal()}
      </DialogContent>
      <DialogActions>
        <MRT_EditActionButtons variant="text" table={table} row={row} />

      </DialogActions>

    </>
*/
  ),

  
  onCreatingRowSave: ({ table, values }) => {
    //validate data
    //save data to api
    table.setCreatingRow(null); //exit creating mode
  },
  onCreatingRowCancel: () => {
    //clear any validation errors
  },

  renderTopToolbarCustomActions: ({ row, table }) => (
    <>
    {/*
    <Button onClick={handleOpen}>Open modal</Button>
    <Modal
        open={open}
        onClose={handleClose}
        PaperProps={{
          component: 'form',
       
          onSubmit: (event) => {
            event.preventDefault();
            const formData = new FormData(event.currentTarget);
            const formJson = Object.fromEntries(formData.entries());
            const email = formJson.email;
            console.log(email);
           },
        }}
        style={{ overflow: 'scroll' }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
         
        <BasicModal />
        <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button type="submit">SubscribeX</Button>
        </DialogActions>
        </Box>
      </Modal>
 */}

   
    <Button
      onClick={() => setCreateModalOpen(true)}
    >
    
      Create New User
  </Button> 


    <CreateNewAccountModal
        rows={row}
        columns={columns}
        open={createModalOpen}
        onClose={() => setCreateModalOpen(false)}
        onSubmit={handleCreateNewRow} 
       />
 
       </>
  ),

  muiDetailPanelProps: () => ({
    sx: (theme) => ({
      backgroundColor:
        theme.palette.mode === 'dark'
          ? 'rgba(255,210,244,0.1)'
          : 'rgba(0,0,0,0.1)',
    }),

  }),

     //custom expand button rotation
  muiExpandButtonProps: ({ row, table }) => ({
      children: row.getIsExpanded() ? <MinusIcon sx={{color:'red'}}/> : <AddIcon />,
      onClick: () => table.setExpanded({ [row.id]: !row.getIsExpanded() }), //only 1 detail panel open at a time
      sx: {
        transform: row.getIsExpanded() ? 'rotate(180deg)' : 'rotate(-90deg)',
        transition: 'transform 0.2s',
      },

  }),

    renderRowActions: ({ row }) => (
      <Box sx={{ display: 'flex', flexWrap: 'nowrap', gap: '8px' }}>
      <Tooltip title="Elimina pratica">
      <IconButton
        color="error"
        onClick={() => deletePratica(row.original)}
      >
       <DeleteIcon />
      </IconButton>
      </Tooltip>
      <Tooltip title="Assegna">
      <IconButton 
      color="secondary"
      onClick={() => handleAssegna(row.original)}>
      <SupervisorAccountIcon />
    </IconButton>
    </Tooltip>
    </Box>
    ),
/*
    renderDetailPanel: ({ row }) => (
      
      <AttiveSub row={row} praticaId={row.original._id}/>

    ),
 */   
     renderDetailPanel: ({ row }) => renderRowSubComponent( row )
});

//example of creating a mui dialog modal for creating new rows

const CreateNewAccountModal = ({ open, rows, columns, onClose, onSubmit }) => {

 // console.log(rows)

  const [values, setValues] = useState(() =>
    columns.reduce((acc, column) => {
      acc[column.accessorKey ?? ''] = '';
      return acc;
    }, {}),

  );

  const handleSubmit = () => {
    //put your validation logic here
    onSubmit(values);
    onClose();
  };

 // const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

  return (

    <Dialog
    open={open}
    onClose={onClose}
    fullWidth={true}
    scroll={scroll}
    PaperProps={{
      component: 'form',
   
      onSubmit: (event) => {
        event.preventDefault();
        const formData = new FormData(event.currentTarget);
        const formJson = Object.fromEntries(formData.entries());

        for (let index = 0; index < formJson.length; index++) {
          console.log(formJson[index].name , formJson[index].value)
        }
        const email = formJson.email;
        const stato = formJson.stato;
        const ruolo = formJson.teleconsul;

        console.log(email, stato, ruolo);
        onClose();
      },
    }}
  >
    <DialogTitle>Create new User</DialogTitle>
    <DialogContent>

    <div>
    
    <Box  sx={{ flexGrow: 1, p: 2 }}>
    {FormElements.form.sections.map((item, i) => {
     // console.log(item);
      return(

        <Grid key={i} container spacing={1}>           
        {item.fields.map((data, i) => {
       //   console.log("data", data)
          return(
            <Grid item key={i} xs={12} sm={4} md={4}>
            <TextField  id="standard-basic" label={data.label}
          //  value={nome}
         //   onChange={(event) => data.name === "nome" ? setNome(event.target.value) : '' }
            variant="standard" name={data.name} />
            </Grid>
          )
        })
       }
        </Grid>   
      )
    })
    }
    </Box>



    <Divider>Indirizzo</Divider>
    {FormElements.form.address.map((item, index) => {
   //   console.log(item);
      return(
        <Box sx={{ flexGrow: 1, p: 2 }} key={index}>
        <Grid container spacing={1}>           
        {item.fields.map(data =>{
        //  console.log("data", data)
          return(
            <Grid item key={data.name} xs={12} sm={4} md={4}>
            <TextField  id="standard-basic" label={data.label} variant="standard" name={data.name} />
            </Grid>
          )
        })
       }
        </Grid>
        </Box>
      )
    })
    }

    <Divider style={{padding: '15px'}}>Ruoli utente</Divider>
   {/* console.log(rolesName) */}
    <FormControl component="fieldset">
    <FormLabel component="legend">Label placement</FormLabel>
   
    {FormElements.form.roles.map((item, index) => {
      return (
        <FormGroup aria-label="position" key={index} row>
        {item.field.map(data =>{
             // console.log("data", data)
                return(
                  <FormControlLabel
                  value={data} 
                  key={data}
                  control={<Checkbox name={data}/>}
                  label={data} 
                  labelPlacement="start"
                />
                )
              })
        }
        </FormGroup>
      )
    })
  }

  </FormControl>


 <Divider style={{marginTop: '20px',padding: '15px'}}>Collaboratore volontario</Divider>

    {FormElements.form.collaboratore.map((item, index) => {
    //  console.log(item);
      return(
        <Box key={index} sx={{ flexGrow: 1, p: 2 }}>
        <Grid container spacing={1}>           
        {item.fields.map(data =>{
    //     console.log("data", data)
          return(
            <Grid item key={data.name} xs={12} sm={4} md={4}>
            <TextField id="standard-basic" label={data.label} variant="standard" name={data.name} />
            </Grid>
          )
        })
       }
        </Grid>
        </Box>
      )
    })
    }

    <div>

    <FormGroup>

      <FormControlLabel  control={<Switch 
      //onChange={handleChangeActiveUser}
      name='stato'
      inputProps={{ 'aria-label': 'controlled' }}/>} label="Richiesto" />

    </FormGroup>
    </div>

    </div>

    </DialogContent>
    <DialogActions>
      <Button onClick={onClose}>Cancel</Button>
      <Button type="submit">Subscribe</Button>
    </DialogActions>
  </Dialog>

   

  );

};

const BasicModal = () => {
  

  return (
    <div>
    <Box  sx={{ flexGrow: 1, p: 2 }}>
    {FormElements.form.sections.map((item, i) => {
      console.log(item);
      return(

        <Grid key={i} container spacing={1}>           
        {item.fields.map((data, i) => {
       //   console.log("data", data)
          return(
            <Grid item key={i} xs={12} sm={4} md={4}>
            <TextField  id="standard-basic" label={data.label}
          //  value={nome}
         //   onChange={(event) => data.name === "nome" ? setNome(event.target.value) : '' }
            variant="standard" name={data.name} />
            </Grid>
          )
        })
       }
        </Grid>   
      )
    })
    }
    </Box>

    <Divider>Indirizzo</Divider>
    {FormElements.form.address.map((item) => {
   //   console.log(item);
      return(
        <Box sx={{ flexGrow: 1, p: 2 }}>
        <Grid container spacing={1}>           
        {item.fields.map(data =>{
        //  console.log("data", data)
          return(
            <Grid item key={data.name} xs={12} sm={4} md={4}>
            <TextField  id="standard-basic" label={data.label} variant="standard" name={data.name} />
            </Grid>
          )
        })
       }
        </Grid>
        </Box>
      )
    })
    }

    <Divider style={{padding: '15px'}}>Ruoli utente</Divider>
    {console.log(rolesName) }
    <FormControl component="fieldset">
    <FormLabel component="legend">Label placement</FormLabel>
    
    {FormElements.form.roles.map((item, index) => {
      return (
        <FormGroup aria-label="position" row>
        {item.field.map(data =>{
          //     console.log("data", data)
                return(
                  <FormControlLabel
                  value={data} 
                  control={<Checkbox />}
                  label={data} 
                  labelPlacement="start"
                />
                )
              })
        }
        </FormGroup>
      )
    })
  }
  </FormControl>


 <Divider style={{marginTop: '20px',padding: '15px'}}>Collaboratore volontario</Divider>
    {FormElements.form.collaboratore.map((item) => {
    //  console.log(item);
      return(
        <Box key={item.name} sx={{ flexGrow: 1, p: 2 }}>
        <Grid container spacing={1}>           
        {item.fields.map(data =>{
    //     console.log("data", data)
          return(
            <Grid item key={data.name} xs={12} sm={4} md={4}>
            <TextField id="standard-basic" label={data.label} variant="standard" name={data.name} />
            </Grid>
          )
        })
       }
        </Grid>
        </Box>
      )
    })
    }
    <div>

    <FormControl component="fieldset" variant="standard">
      <FormLabel component="legend">Assign responsibility</FormLabel>
      <FormGroup>
        <FormControlLabel
          control={
            <Switch  name="gilad" />
          }
          label="Gilad Gray"
        />
        <FormControlLabel
          control={
            <Switch  name="jason" />
          }
          label="Jason Killian"
        />
        <FormControlLabel
          control={
            <Switch  name="antoine" />
          }
          label="Antoine Llorca"
        />
      </FormGroup>

    </FormControl>
    </div>

    </div>
  );
}

  return <MaterialReactTable table={table} />;

};

export default TableUsersAdminContenitore;
