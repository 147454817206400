import * as React from 'react';
import axios from "axios";
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Endpoints from '../../../Component/services/Endpoints';
//import FileUpload from '../../File_Upload_Component/file-upload.component'

const { REACT_APP_API_ENDPOINT } = process.env;



export default function FormMessageDialog({ sendId, descrizione, target, operatoreAttivo, openDialog, setOpenDialog }) {
  
    const handleClose = () => {
        setOpenDialog(false)
    };

    const handleMessaggi = (event) => {
        event.preventDefault();
            const formData = new FormData(event.currentTarget);
            const formJson = Object.fromEntries(formData.entries());
            const email = formJson.email;
            console.log(email);

      //  console.log(selectedConsulente)

      //  let currentUser = currentLoggedUser;

        const praticaId = formJson.praticaId;
        let sender = formJson.sender;
        let receiver = formJson.receiver;
        let to = target._id
        let from = operatoreAttivo._id;
        let messaggio = formJson.messaggio;

     //   const formData = new FormData(event.currentTarget);
/*
        for (let [key, value] of formData.entries()) {
            if (key === "idpratica") {
                praticaId = value;
            }
            if (key === "sender") {
                sender = value;
            }
            if (key === "receiver") {
                receiver = value;
            }
            if (key === "messaggio") {
                messaggio = value;
            }
            console.log(key, value);
        }
*/
        let msgObj = {
            idPratica: praticaId,
            descrizione: descrizione,
            users: [from, to],
            sender: from,
            senderName: sender,
            receiverName: receiver,
            message: messaggio,
            createdAt: new Date().toLocaleString('it-IT', { timeZone: 'UTC' })
        }
        console.log(msgObj);
        handleClose();
        /*
        const headers = {
            "Content-Type": "application/json",
        }
        axios.post(`${REACT_APP_API_ENDPOINT}/api/direct/message`, msgObj, {
            headers: headers,
        }).then(response => { // then print response status
            console.log(response);
            //setDrawerOpen(!drawerOpen);
        })
*/

        /*
         idpratica 62406488d5e50f7df66067bd PraticheLavorazione.js:280
        sender Di Giacomo Martina PraticheLavorazione.js:280
        receiver Sandro Test PraticheLavorazione.js:280
        messaggio dsdssffdsdf
                console.log(`${praticaId} ${sender} ${receiver} ${messaggio}`);

        */
    }

  return (
    <React.Fragment>

    OpenDialog ? 
    
      <Dialog
      open={openDialog} 
      onClose={handleClose}
        PaperProps={{
          component: 'form',
          onSubmit: (event) => handleMessaggi(event),
        }}
      >
        <DialogTitle>Messaggio</DialogTitle>
        <DialogContent>
          <DialogContentText>
          {/* JSON.stringify(sendId) */}
          {/* JSON.stringify(target) */}
            Invia messaggio al consulente per questa pratica.
          </DialogContentText>
          <TextField
          autoFocus
          required
          margin="dense"
          id="praticaId"
          name="praticaId"
          label="id pratica"
          type="text"
          value={sendId}
          fullWidth
          variant="standard"
          
        />
        <TextField
        autoFocus
        required
        margin="dense"
        id="descrizione"
        name="descrizione"
        label="Descrizione"
        type="text"
        value={descrizione}
        fullWidth
        variant="standard"
        disabled
      />
        <TextField
        autoFocus
        required
        margin="dense"
        id="receiver"
        name="receiver"
        label="Consulente"
        type="text"
        value={`${target.nome} ${target.cognome}`}
        fullWidth
        variant="standard"
      />
          <TextField
            autoFocus
            required
            margin="dense"
            id="emailconsulente"
            name="emailconsulente"
            label="Email Consulente"
            type="email"
            value={target.email}
            fullWidth
            variant="standard"
          />
          <TextField
          autoFocus
          required
          margin="dense"
          id="sender"
          name="sender"
          label="operatore"
          type="text"
          value={`${operatoreAttivo.nome} ${operatoreAttivo.nome}`}
          fullWidth
          variant="standard"
        />
        <TextField
        id="messaggio"
        name="messaggio"
        label="messaggio"
        multiline
        maxRows={4}
        fullWidth
            variant="standard"
      />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button type="submit">Subscribe</Button>
        </DialogActions>
      </Dialog>
: null

    </React.Fragment>
  );
}