import { useMemo, useState } from 'react';
import axios from "axios";
import { MuiFileInput } from 'mui-file-input'
import { MaterialReactTable } from 'material-react-table';
import { createTheme, ThemeProvider, useTheme } from '@mui/material';
//Import Material React Table Translations

import { MRT_Localization_IT } from 'material-react-table/locales/it';
import {
  MRT_EditActionButtons as MrtEditActionButtons,
  // createRow,
  useMaterialReactTable,
} from 'material-react-table';
//Import Material React Table Translations
//Import Material React Table Translations
import { itIT } from '@mui/material/locale';
import {
  Box,
  Button,
  TextField,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Tooltip,
  Stack,
} from '@mui/material';

import LinearProgress from '@mui/material/LinearProgress';
import CloseIcon from '@mui/icons-material/Close'


import {
  QueryClient,
  QueryClientProvider,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';

import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

const ManegedModuli = () => {
  const [validationErrors, setValidationErrors] = useState({});

  const [file, setFile] =  useState([]);
  const [newFileName, setNewFileName] =  useState('');
  const [newContenuto, setNewContenuto] =  useState('');
  const [newMessaggio, setNewMessaggio] =  useState('');
  const [newTipo, setNewTipo]=useState('');
  const [progress, setProgress] = useState(0);



  const columns = useMemo(
    () => [
      {
        accessorKey: '_id',
        header: '_Id',
        enableEditing: false,
        size: 80,
      },
      {
        accessorKey: 'tipo',
        header: 'Tipo',
        muiEditTextFieldProps: {
          required: true,
          error: !!validationErrors?.tipo,
          helperText: validationErrors?.tipo,
          //remove any previous validation errors when user focuses on the input
          onFocus: () =>
            setValidationErrors({
              ...validationErrors,
              tipo: undefined,
            }),
          //optionally add validation checking for onBlur or onChange
        },
      },
      {
        accessorKey: 'contenuto',
        header: 'Contenuto',
        muiEditTextFieldProps: {
          required: true,
          error: !!validationErrors?.contenuto,
          helperText: validationErrors?.contenuto,
          //remove any previous validation errors when user focuses on the input
          onFocus: () =>
            setValidationErrors({
              ...validationErrors,
              contenuto: undefined,
            }),
        },
      },
      {
        accessorKey: 'documento',
        header: 'Documento',
        muiEditTextFieldProps: {
          required: true,
          error: !!validationErrors?.documento,
          helperText: validationErrors?.documento,
          //remove any previous validation errors when user focuses on the input
          onFocus: () =>
            setValidationErrors({
              ...validationErrors,
              documento: undefined,
            }),
        },
      },
      {
        accessorKey: 'messaggio',
        header: 'messaggio',
        muiEditTextFieldProps: {
          required: true,
          error: !!validationErrors?.messaggio,
          helperText: validationErrors?.messaggio,
          //remove any previous validation errors when user focuses on the input
          onFocus: () =>
            setValidationErrors({
              ...validationErrors,
              messaggio: undefined,
            }),
        },
      },
    ],
    [validationErrors],
  );
   
  const [values, setValues] = useState(() =>
  columns.reduce((acc, column) => {
  acc[column.accessorKey ?? ''] = '';
  return acc;
  }, {}),

);


  const HandleContenuto=(event)=>{
    setNewContenuto(event.target.value);
  }

  const HandleMessaggio=(event)=>{
    setNewMessaggio(event.target.value)
  }
  const HandleTipo=(event)=>{
    setNewTipo(event.target.value)
  }

  const ResetAllContent = ()=> {

          setFile([]);
          setProgress(0);
          setNewFileName('');
          setNewContenuto('');
          setNewTipo('');
          setNewMessaggio('');

  }

  //call CREATE hook
  const { mutateAsync: createUser, isPending: isCreatingUser } = useCreateUser({ResetAllContent});
  
  //call READ hook
  const {
    data: fetchedUsers = [],
    isError: isLoadingUsersError,
    isFetching: isFetchingUsers,
    isLoading: isLoadingUsers,
  } = useGetUsers();
  
  //call UPDATE hook
  const { mutateAsync: updateUser, isPending: isUpdatingUser } =  useUpdateUser(file, newFileName);
  //call DELETE hook
  const { mutateAsync: deleteUser, isPending: isDeletingUser } =  useDeleteUser();

  //CREATE action
  const handleCreateUser = async ({ values, userState, table }) => {


    console.log(userState)
    const newModulo = {
      tipo: newTipo,
      contenuto: newContenuto,
      messaggio: newMessaggio,
      documento: [newFileName]
    }
    /*
    const newValidationErrors = validateUser(valuesx);
    if (Object.values(newValidationErrors).some((error) => error)) {
      setValidationErrors(newValidationErrors);
      return;
    }
    */
    setValidationErrors({});
    await createUser(newModulo);
    table.setCreatingRow(null); //exit creating mode
  };

  //UPDATE action
  const handleSaveUser = async ({ values, table }) => {
    const newValidationErrors = validateUser(values);
    if (Object.values(newValidationErrors).some((error) => error)) {
      setValidationErrors(newValidationErrors);
      return;
    }
    setValidationErrors({});
    await updateUser(values);
    table.setEditingRow(null); //exit editing mode
  };

  //DELETE action
  const openDeleteConfirmModal = (row) => {
    if (window.confirm('Attenzione, se questo modulo è stato associato ad un tipo pratica, eliminare prima quella e poi il modulo. Si è sicuri di Voler continuare?')) {
      deleteUser(row.original._id);
    }
  };

  const handleChange = (newFile) => {
    if (newFile) {
      if (newFile.type !== "application/pdf") {
        alert("Solo file PDF sono accettati");
        return
      }
      setProgress(0);
      setFile(newFile);
      UploadFile(newFile);
      
    } else {
      setFile([]);
      setProgress(0);
      setNewFileName('')
     // UploadFile(newFile);
    }
  
  }

  const UploadFile = (files) => {

    if (files) {
      setNewFileName('')
    let filex = [files]
    const dataDoc = new FormData()
    for(var x = 0; x<filex.length; x++) {
        dataDoc.append('docCollection', filex[x]);
     //   data.append('name', 'ricevitabis');

    }

   const headers = {
    "Content-Type": "multipart/form-data",
    }

    axios.post(`https://sgp.lambdamedia.it:5001/documenti`, dataDoc, {
    headers: headers,
      // receive two    parameter endpoint url ,form data
      onUploadProgress: ProgressEvent => {
      setProgress((ProgressEvent.loaded / ProgressEvent.total*100))
  },
  })

  .then(response => { // then print response status
    console.log(response);

      let documentoArray = [];

       for(let i =0; i< response.data.length; i++){
        documentoArray.push(
          response.data[i].filename
         )
       }
       setNewFileName(response.data[0].filename)
      // setDocumentoFile(documentoArray);
  })
}
}

function useUpdateUser(file, newFileName,) {
  const queryClient = useQueryClient();
  return useMutation({
    
    mutationFn: async (user) => {
      console.log(user);
      console.log("USER", user, "FILEnew", newFileName)
      const documentoNew = [];
      if (file) {documentoNew.push(newFileName);
      } else {  documentoNew.push(file.name);}
      
      let newContent = ''

      if (newContenuto) {newContent = newContenuto} 
      else {newContent = user.contenuto}

      let newMessages = '';
      if (newMessaggio) {newMessages = newMessaggio} 
      else {newMessages = user.messaggio}

    axios.post(`https://sgp.lambdamedia.it:5001/admin/moduli/edit/id?_id=${user._id}`, {
        tipo: user.tipo,
        messaggio: newMessages,
        documento: documentoNew,
        contenuto: newContent       
      })
      .then(function (response) {
       /*
        if (response.data.success) {
          axios.get(`${REACT_APP_API_ENDPOINT}/api/admin/moduli/getone/id?_id=${user._id}`)
            .then(function (resp) {
              // handle success
              console.log(resp.data[0]);
              return resp.data[0]
            })
            .catch(function (error) {
              // handle error
              console.log(error);
              return error
            })
            .finally(function () {
              // always executed
            });

        }
*/
       
        console.log(response);
        setFile([]);
        setProgress(0);
        fetchedUsers();

      
     //   window.location.reload(true);
      })
      .catch(function (error) {
        console.log(error);
      });
       
      //send api update request here
      await new Promise((resolve) => setTimeout(resolve, 1000)); //fake api call
      return Promise.resolve();
    },
    //client side optimistic update
/*
    onMutate: (newUserInfo) => {
      queryClient.setQueryData(['users'], (prevUsers) =>
        prevUsers?.map((prevUser) =>
          prevUser._id === newUserInfo._id ? newUserInfo : prevUser,
        ),
      );
    },
*/
     onSettled: () => queryClient.invalidateQueries({ queryKey: ['users'] }), //refetch users after mutation, disabled for demo
  });
}


  const table = useMaterialReactTable({
    columns,
    data: fetchedUsers,
    createDisplayMode: 'modal', //default ('row', and 'custom' are also available)
    editDisplayMode: 'modal', //default ('row', 'cell', 'table', and 'custom' are also available)
    enableEditing: true,
    localization: MRT_Localization_IT,
    getRowId: (row) => row.id,
    muiToolbarAlertBannerProps: isLoadingUsersError
      ? {
          color: 'error',
          children: 'Error loading data',
        }
      : undefined,
    muiTableContainerProps: {
      sx: {
        minHeight: '500px',
      },
    },
    onCreatingRowCancel: () => setValidationErrors({}),
    onCreatingRowSave: handleCreateUser,
    onEditingRowCancel: () => setValidationErrors({}),
    onEditingRowSave: handleSaveUser,
    //optionally customize modal content
    renderCreateRowDialogContent: ({ table, row, internalEditComponents, onClose, useGetUsers }) => (
      <>
        <DialogTitle textAlign="center">Crea un modulo</DialogTitle>
        <DialogContent>
       
          <Stack
            sx={{
              width: '100%',
              minWidth: { xs: '300px', sm: '360px', md: '400px' },
              gap: '1.5rem',
            }}
          >          
          <TextField
          key={"tipo"}
          label={"Tipo"}
          name={"tipo"}
          onChange={(e) => HandleTipo(e)   }
        />

        <TextField
        key={"contenuto"}
        label={"Contenuto"}
        name={"contenuto"}
        onChange={(e) => HandleContenuto(e)   }
      />

      <TextField
      key={"messaggio"}
      label={"Messaggio"}
      name={"messaggio"}
      onChange={(e) => HandleMessaggio(e)   }
    />

    <MuiFileInput value={file} onChange={handleChange}  placeholder="Insert a file" fullWidth
    clearIconButtonProps={{
        title: "Remove",
        children: <CloseIcon fontSize="small" />
      }}
      inputProps={{ accept: '.pdf' }}
    //  getSizeText={(value) => 'Very big'}
    // {...register("file")}
    />
    <Box sx={{ width: '100%' }}>
    <LinearProgress variant="determinate" value={progress} />
    </Box> 
          </Stack>
   
        </DialogContent>
        <DialogActions sx={{ p: '1.25rem' }}>
        <MrtEditActionButtons variant="contained" color="secondary" table={table} row={row} />
        </DialogActions>
      </>
    ),
    //optionally customize modal content
    renderEditRowDialogContent: ({ table, row, internalEditComponents }) => (
      <>
        <DialogTitle variant="h3">Modifica Modulo</DialogTitle>
        <DialogContent
          sx={{ display: 'flex', flexDirection: 'column', gap: '1.5rem' }}
        >
          {/*internalEditComponents*/} {/* or render custom edit components here */}
          <Stack
          sx={{
            width: '100%',
            minWidth: { xs: '300px', sm: '360px', md: '400px' },
            gap: '1.5rem',
            padding: '25px'
          }}
        >
          <TextField 
          fullWidth 
          value={row.original.tipo}
          label="tipo" 
          id="tipo" 
          name='tipo'
          />
          
          <TextField 
          fullWidth 
          defaultValue={row.original.messaggio}
          onChange={HandleMessaggio}
          label="messaggio" 
          id="messaggio"
          name="messaggio"
          />
  
            
          <TextField 
          fullWidth 
          value={row.original.documento}
          label="documento attuale" 
          id="documento" 
          name='documento'
          />
       
          <TextField 
          fullWidth 
          defaultValue={row.original.contenuto}
          onChange={HandleContenuto}
          label="contenuto" 
          id="contenuto" 
          name='contenuto'
          />
    
          <MuiFileInput value={file} onChange={handleChange}  placeholder="Insert a file" fullWidth
          clearIconButtonProps={{
              title: "Remove",
              children: <CloseIcon fontSize="small" />
            }}
            inputProps={{ accept: '.pdf' }}
          //  getSizeText={(value) => 'Very big'}
         // {...register("file")}
          />
          <Box sx={{ width: '100%' }}>
          <LinearProgress variant="determinate" value={progress} />
        </Box>    
        </Stack>
        </DialogContent>
        <DialogActions>
          <MrtEditActionButtons variant="text" table={table} row={row} />
        </DialogActions>
      </>
    ),
    renderRowActions: ({ row, table }) => (
      <Box sx={{ display: 'flex', gap: '1rem' }}>
        <Tooltip title="Edit">
          <IconButton onClick={() => table.setEditingRow(row)}>
            <EditIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="Delete">
          <IconButton color="error" onClick={() => openDeleteConfirmModal(row)}>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      </Box>
    ),
    renderTopToolbarCustomActions: ({ table }) => (
      <Button
      variant="contained"
      onClick={() => {
        table.setCreatingRow(true); //simplest way to open the create row modal with no default values
        //or you can pass in a row object to set default values with the `createRow` helper function
        // table.setCreatingRow(
        //   createRow(table, {
        //     //optionally pass in default values for the new row, useful for nested data or other complex scenarios
        //   }),
        // );
      }}

    >

      Crea Modulo

    </Button>
    ),
    state: {
      isLoading: isLoadingUsers,
      isSaving: isCreatingUser || isUpdatingUser || isDeletingUser,
      showAlertBanner: isLoadingUsersError,
      showProgressBars: isFetchingUsers,
    },
  });

  const theme = useTheme();

  return (
  <ThemeProvider theme={createTheme(theme, itIT)}>
  <MaterialReactTable table={table}/>
  </ThemeProvider> 
)
};

//CREATE hook (post new user to api)
function useCreateUser({ResetAllContent}) {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (user, _id) => {

      console.log(user)
      //send api update request here
  /*     const newObj = {
        tipo:userx.tipo,
        documento: [fileNewName],
        contenuto: userx.contenuto,
        messaggio: userx.messaggio
      } */
      axios.post(`https://sgp.lambdamedia.it:5001/admin/moduli/create/`, user)
      .then(function (response) {
        console.log(response);
        alert(JSON.stringify(response.data));
       
     //   RefetchData();
     ResetAllContent();
     _id = response.data.modulo_id;
       
    })
      await new Promise((resolve) => setTimeout(resolve, 1000)); //fake api call
      return Promise.resolve();
    },
    //client side optimistic update
    /*
    onMutate: (newUserInfo, _id) => {
      queryClient.setQueryData(['users'], (prevUsers) => [
        ...prevUsers,
        {
          ...newUserInfo,
          _id: _id,
        },
      ]);
    }
    */
    
     onSettled: () => queryClient.invalidateQueries({ queryKey: ['users'] }), //refetch users after mutation, disabled for demo
  });
}

//READ hook (get users from api)
function useGetUsers() {
  return useQuery({
    queryKey: ['users'],
    queryFn: async () => {

      let promise = new Promise(function (resolve, reject) {
        let req = new XMLHttpRequest();
        req.open("GET", `https://sgp.lambdamedia.it:5001/admin/moduli/`);
        req.responseType = 'json';
        req.onload = function () {
          if (req.status === 200) {
            resolve(req.response);
          } else {
            reject("There is an Error!");
          }
        };
        req.send();
      });
      //send api request here
      await new Promise((resolve) => setTimeout(resolve, 1000)); //fake api call
      /*
      const fetchData = async () => {
       
        const url = new URL(`https://sgp.lambdamedia.it:5001/admin/moduli/`,
           process.env.NODE_ENV === 'production'
             ? 'https://www.material-react-table.com'
             : 'http://localhost:3000',
         );
   
         try {
           const response = await fetch(url.href);
           const json = await response.json();
           return json
         } catch (error) {
           console.error(error);
           return;
         }
       };
*/
      return promise;
    },
    refetchOnWindowFocus: true,
  });
}

//UPDATE hook (put user in api)

//DELETE hook (delete user in api)
function useDeleteUser() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (user_id, user) => {
      //send api update request here
      axios.delete(`https://sgp.lambdamedia.it:5001/admin/moduli/deleteone/id?_id=${user_id}`)
      .then(function (response) {
        console.log(response);
        alert(JSON.stringify(response.data));
             
    })
      await new Promise((resolve) => setTimeout(resolve, 1000)); //fake api call
      return Promise.resolve();
    },
    //client side optimistic update
    /*
    onMutate: (userId) => {
      queryClient.setQueryData(['users'], (prevUsers) =>
        prevUsers?.filter((user) => user.id !== userId),
      );
    },
   */
   
    onSettled: () => queryClient.invalidateQueries({ queryKey: ['users'] }), //refetch users after mutation, disabled for demo
  });
}

const queryClient = new QueryClient();

const GestioneModuli = () => (
  
  //Put this with your other react-query providers near root of your app
  <QueryClientProvider client={queryClient}>
    <ManegedModuli />
  </QueryClientProvider>
);

export default GestioneModuli;

const validateRequired = (value) => !!value.length;
const validateEmail = (email) =>
  !!email.length &&
  email
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    );

function validateUser(user) {
  return {
    tipo: !validateRequired(user.tipo)
      ? 'Tipo is Required'
      : '',
  //  messaggio: !validateRequired(user.messaggio) ? 'Messaggio is Required' : '',
    
  };
}
