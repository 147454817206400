import { useMemo, useState } from 'react';
import {
  MaterialReactTable,
  useMaterialReactTable
} from 'material-react-table';
import axios from "axios";
import socketIOClient from "socket.io-client";
import Swal from 'sweetalert2';
import { IconButton, Tooltip } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import MinusIcon from '@mui/icons-material/Remove';
import { Box  } from '@mui/material';
//import FileUpload from '../../File_Upload_Component/file-upload.component'
import { Delete as DeleteIcon }from '@mui/icons-material';
import { MRT_Localization_IT } from 'material-react-table/locales/it';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import RefreshIcon from '@mui/icons-material/Refresh';
import CiuseAdminSubRows from './CiuseAdminSubRows'
import {
  QueryClient,
  QueryClientProvider,
  keepPreviousData,
  useQuery,
} from '@tanstack/react-query'; //note: this is TanStack React Query V5

const {REACT_APP_API_ENDPOINT} = process.env;


const ChiuseAdmin = () => {
  //manage our own state for stuff we want to pass to the API
  const [columnFilters, setColumnFilters] = useState([]);
  const [globalFilter, setGlobalFilter] = useState('');
  const [sorting, setSorting] = useState([]);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });

  const [selectedIdPratica, setSelectedIdPratica] = useState('');

  //consider storing this code in a custom hook (i.e useFetchUsers)
  const {
    data: { data = [], meta } = {}, //your data and api response will probably be different
   // data,
    isError,
    isRefetching,
    isLoading,
    refetch,
  } = useQuery({
    queryKey: [
      'table-data',
      columnFilters, //refetch when columnFilters changes
      globalFilter, //refetch when globalFilter changes
      pagination.pageIndex, //refetch when pagination.pageIndex changes
      pagination.pageSize, //refetch when pagination.pageSize changes
      sorting, //refetch when sorting changes
    ],
    queryFn: async () => {
      const fetchURL = new URL(`${REACT_APP_API_ENDPOINT}/api/pratiche/remotechiuse`,
        process.env.NODE_ENV === 'production'
          ? 'https://www.material-react-table.com'
          : 'http://localhost:3000',
      );

      //read our state and pass it to the API as query params
      fetchURL.searchParams.set(
        'start',
        `${pagination.pageIndex * pagination.pageSize}`,
      );
      fetchURL.searchParams.set('size', `${pagination.pageSize}`);
      fetchURL.searchParams.set('filters', JSON.stringify(TransfomFilterData() ?? []));
      fetchURL.searchParams.set('globalFilter', globalFilter ?? '');
      fetchURL.searchParams.set('sorting', JSON.stringify(sorting ?? []));

      //use whatever fetch library you want, fetch, axios, etc
      const response = await fetch(fetchURL.href);
      const json = await response.json();
      return json;
    },
    placeholderData: keepPreviousData, //don't go to 0 rows when refetching or paginating to next page
  });

  const TransfomFilterData = () => {
    let bio = columnFilters;
    let array = [];
    for(var i =0; i<bio.length; i++){

  let filterQuery = '';

  console.log(bio[i].id.toString()) //access the object property 
  console.log(bio[i].value.toString())
  let a = bio[i].id.toString();
  let b = bio[i].value.toString();
    console.log(a, b)
    if (a === 'pratica_numero') {
      filterQuery = { statoPratica : "Chiusa", [a] : parseInt(b)};
    } else
    filterQuery = { statoPratica : "Chiusa", [a] : {'$regex' : '^' + b, '$options' : 'i'}};
  console.log(filterQuery)
  array.push(filterQuery)
  }
 console.log(array); 
//Object.keys(bio).forEach(key => delete object[key]);
    return array
  }

    //qui mi assegno la pratica
const handleShow = (event) => {

  console.log(event.cell.row.values._id);
  var idPratica = event.cell.row.values._id;
  const descrConfirm = event.cell.row.values.descrizione;
  const aConfirmNome = event.cell.row.original.assistito.nome;
  const aConfirmCognome = event.cell.row.original.assistito.cognome;

  const cConfirmNome = event.cell.row.original.consulente.nome;
  const cConfirmCognome = event.cell.row.original.consulente.cognome;

  const oConfirmNome = event.cell.row.original.operatore[0].nome;
  const oConfirmCognome = event.cell.row.original.operatore[0].cognome;

  const adminClient =JSON.parse(localStorage.getItem('currentUser'));

if(adminClient.role[0] === 'ROLE_ADMIN'){
  Swal.fire({
    title: 'Vuoi davvero eliminare?',
    text: `${descrConfirm}`,
    icon: 'warning',
    html: `<b>Descrizione: </b>${descrConfirm}<br><b>Consulente: </b>${cConfirmCognome} ${cConfirmNome} <br> <b> Assistito: </b> ${aConfirmCognome} ${aConfirmNome}` ,
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: 'SI, elimina!',
    footer: `<b>Operatore: <b/>${oConfirmCognome} ${oConfirmNome}`,
  }).then((result) => {
    if (result.isConfirmed) {
//inizio conferma seleziona
   // qui elimino la pratica
    fetch(`${REACT_APP_API_ENDPOINT}/api/pratica/eliminazione/action/id?_id=${idPratica}`, {
      headers: {
        'Content-Type': 'application/json',
      },
     
    })
      .then(res => res.json())
      .then(data => {

        if(data.success){

       //   renewTable();

          Swal.fire(
            'Eliminita!',
            data.success,
            'Grazie'
          )

       }
       if(data.error){
        Swal.fire(
          'Error!',
          data.error,
          'Grazie'
        )
          }

      })
      .catch(error => {
      // enter your logic for when there is an error (ex. error toast)
      console.log(error)
      });

      //fine conferma seleziona
    }
  })

} else {
  Swal.fire(
    'Non hai i privilegi per eseguire questa azione',
    'Grazie'
  )
 
}

}

const handleAssegna = (original) => {
  console.log(original)

    let arrayOpe = []
    const operatoriFetch = async () => {
    const response2 = await fetch(`${REACT_APP_API_ENDPOINT}/api/users/seleziona/operatori/`);
    const operator = await response2.json();
 //     console.log(pratiche);
     arrayOpe = operator;

     var options = {};

     let test = arrayOpe.map(x => options[x._id] = x.cognome + " " + x.nome);
    console.log(test);
   
    let pretext =original; 
   
     Swal.fire({
      title: `Riassegnazione`,
      iconHtml: `<svg xmlns="http://www.w3.org/2000/svg" height="48" viewBox="0 -960 960 960" width="48">
      <path d="M40-160v-112q0-34 17.5-62.5T104-378q62-31 126-46.5T360-440q66 0 130 15.5T616-378q29 15 46.5 43.5T680-272v112H40Zm720 0v-120q0-44-24.5-84.5T666-434q51 6 96 20.5t84 35.5q36 20 55 44.5t19 53.5v120H760ZM360-480q-66 0-113-47t-47-113q0-66 47-113t113-47q66 0 113 47t47 113q0 66-47 113t-113 47Zm400-160q0 66-47 113t-113 47q-11 0-28-2.5t-28-5.5q27-32 41.5-71t14.5-81q0-42-14.5-81T544-792q14-5 28-6.5t28-1.5q66 0 113 47t47 113ZM120-240h480v-32q0-11-5.5-20T580-306q-54-27-109-40.5T360-360q-56 0-111 13.5T140-306q-9 5-14.5 14t-5.5 20v32Zm240-320q33 0 56.5-23.5T440-640q0-33-23.5-56.5T360-720q-33 0-56.5 23.5T280-640q0 33 23.5 56.5T360-560Zm0 320Zm0-400Z"/></svg>`,
      html:
      `<b>_id pratica: ${pretext._id}</b><br>
       Descrizione: <b>${pretext.descrizione}</b><br>
       Consulente: <b> ${pretext.consulente.cognome} ${pretext.consulente.nome}</b><br>
       Assistito: <b> ${pretext.assistito.cognome} ${pretext.assistito.nome}</b><br>  
       Ultimo Operatore: <b> ${pretext.storico_operatore[pretext.storico_operatore.length - 1].cognome} ${pretext.storico_operatore[pretext.storico_operatore.length - 1].nome}</b><br>
       <b>stato pratica: ${pretext.statoPratica}</b><br>`,
       input: 'select',
       inputOptions: options,
       inputPlaceholder: 'Seleziona Operatore di destinazione',
       showCancelButton: true,
       inputValidator: (value) => {
         return new Promise((resolve) => {
           if (value) {           
            const found = arrayOpe.find(element => element._id === value);
            console.log(found)

            Swal.fire({
              icon: 'warning',
              title: 'Sei sicuro?',
              html:
              `<b>Operatore</b><br>
                _id: <b>${found._id}</b><br>
               Nome: <b> ${found.nome}</b><br>
               Cognome: <b>${found.cognome}</b><br>
               Nome: <b> ${found.nome}</b><br>`,
              showCancelButton: true,
              confirmButtonText: 'Si è tutto ok!',
              cancelButtonText: `Annulla`,
            }).then((result) => {
              /* Read more about isConfirmed, isDenied below */
              if (result.isConfirmed) {

                handleAssegnaPratica(found, pretext);
           //     Swal.fire('Pratica assegnata!')
              } else  {
                Swal.fire('Operazione annullata')
              }
            })
             resolve()
           } else {
             resolve('Devi selezionare un operatore :)')
           }
         })
       }
     })

    };
    operatoriFetch();
}

const handleAssegnaPratica = (operatoreSelezionato, pretext) => {

  console.log(pretext._id);
  
  var idPratica = pretext._id;
  let decrizionePratica = pretext.descrizione;
  let consulente = pretext.consulente;
  let consulenteMail = pretext.consulente.email;
  let assistito = pretext.assistito;
  const operatoreClient = operatoreSelezionato;
 // const {_id, cognome, nome, email, role} = operatoreClient;
  const {_id} = operatoreClient;

          // qui modifico la pratica
          fetch(`${REACT_APP_API_ENDPOINT}/api/admin/riassegna/operatore:id?_id=${idPratica}`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(operatoreClient)
          })
            .then(res => res.json())
            .then(data => {


              const {_id, cognome, nome, email, role} = operatoreClient;


              if(data.success){

                let notifyObj = {
                  emailto: consulenteMail,
                  idpratica: idPratica,
                  descrizione: decrizionePratica,
                  stato: 'Assegnata',
                  assistito: assistito,
                  operatore: `${operatoreClient.cognome} ${operatoreClient.nome}`,
                  contatti: `Cell: ${operatoreClient.cellulare} - Tel: ${operatoreClient.telefono} Email: ${operatoreClient.email}`
                };

           //     notificaAlConsulente(notifyObj);

              }

              let assegnaObj = {
                _id: _id,
                cognome: cognome,
                nome: nome,
                email: email,
                role: role,
                date: new Date().toLocaleString('it-IT'),
                consulente: consulente,
                assistito: assistito,
                praticaId: idPratica,
                descrizione: decrizionePratica
              }

              const socket = socketIOClient(`${REACT_APP_API_ENDPOINT}/api/`);
              socket.emit('adminAction', JSON.stringify(assegnaObj));
            //  console.log("data? ",data)

              const doFetch2 = async () => {
            //  setSpinnerLoading(true);
      
              const response = await fetch(`${REACT_APP_API_ENDPOINT}/api/pratiche/box/generale/`);
              const pratiche = await response.json();
           //     console.log(pratiche);
        //   setData(pratiche);
              //  setSpinnerLoading(false);
              };
              doFetch2();

            })
            .catch(error => {
            // enter your logic for when there is an error (ex. error toast)
            console.log(error)
            });

   

}

  function notificaAlConsulente(obj){
    //notifico con mail al consulente
    axios.post(`${REACT_APP_API_ENDPOINT}/api/consulente/notifica/action`, obj)
    .then(function (response) {
      console.log(response.data);
    })
    .catch(function (error) {
      console.log(error);
    });
}

/* const renewTableAfterDelete = (val) => {
  //setData((data) => data.filter(({_id}) => _id !== val.praticaId));

}

const renewTable = (val) =>{       
  //setData((data) => data.filter(({_id}) => _id !== val));  
} */


function praticaSelezionata( praticaID ) {
setSelectedIdPratica(praticaID)
console.log("praticaSelezionata", praticaID)
}
        
function deletePratica( row ) {
   
  var idPratica = row.original._id;
  const adminClient =JSON.parse(localStorage.getItem('currentUser'));

    if(adminClient.role[0] === 'ROLE_ADMIN'){
      Swal.fire({
        title: `Vuoi davvero eliminare la pratica n° ${row.original.pratica_numero}?`,
        icon: 'danger',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'SI, elimina!',
        footer: `<b>Admin<b/>`,
      }).then((result) => {
        if (result.isConfirmed) {
    //inizio conferma seleziona
      // qui elimino la pratica
        fetch(`${REACT_APP_API_ENDPOINT}/api/pratiche/eliminazione/action/id?_id=${idPratica}`, {
          headers: {
            'Content-Type': 'application/json',
          },
        
        })
      .then(res => res.json())
      .then(data => {
        if(data.success){

          refetch();

          /*
          let updTbl = {
            praticaId: idPratica
          } */ 
         // renewTableAfterDelete(updTbl);
      //   data.splice(row.index, 1); //assuming simple data table

        // setData([...data]);


          Swal.fire(
            'Eliminata!',
            'Un documento eliminato con successo.',
           // data.success,
            'Grazie'
          )

       }
       if(data.error){
        Swal.fire(
          'Error!',
          data.error,
          'Grazie'
        )
          }

      })
      .catch(error => {
      // enter your logic for when there is an error (ex. error toast)
      console.log(error)
      });

      //fine conferma seleziona
    }
  })

} else {
  Swal.fire(
    'Non hai i privilegi per eseguire questa azione',
    'Grazie'
  )
 
}
}

  const columns = useMemo(
    () => [
      {
        accessorKey: '_id',
     //   enableHiding: false,
        header: '_id',
       
      },
      {
        accessorKey: 'pratica_numero',
        header: 'id',
        minSize: 20, //min size enforced during resizing
        maxSize: 80, //max size enforced during resizing
        size: 20, //medium column
      },
      //column definitions...
      {
        accessorKey: 'descrizione',
        header: 'Descrizione',
      },
      {
        accessorKey: 'assistito.regione',
        header: 'Regione',
        minSize: 40, //min size enforced during resizing
        maxSize: 80, //max size enforced during resizing
        size: 50, //medium column
      },
      {
        accessorKey: 'assistito.cognome',
        header: 'Assistito',
        minSize: 40, //min size enforced during resizing
        maxSize: 80, //max size enforced during resizing
        size: 50, //medium column
      },
 /*  */
      {
        //accessorFn function that combines multiple data together
      accessorFn: (row) => (row.assistito.codice_fiscale_assistito ? row.assistito.codice_fiscale_assistito : undefined),
      id: 'assistito.codice_fiscale_assistito',
      header: 'CF.Assistito',
      minSize: 40, //min size enforced during resizing
        maxSize: 80, //max size enforced during resizing
        size: 50, //medium column
      },

      {
        //accessorFn function that combines multiple data together
      accessorFn: (row) => `${row.consulente.nome} ${row.consulente.cognome}`,
      id: 'consulente.cognome',
      header: 'Consulente',
      },
/*
      {
        accessorFn: (row) => `${row.statoPratica} `,
        id: 'statoPratica',
        header: 'Stato',
      }, */
      {
        accessorFn: (row) => `${row.data_scadenza} `,
        id: 'data_scadenza',
        header: 'Scade il',
        minSize: 40, //min size enforced during resizing
        maxSize: 80, //max size enforced during resizing
        size: 50, //medium column
      },
      
            /*
      {
        accessorKey: d => `${d.storico_pratica[d.storico_pratica.length - 1].data}`,
        header: 'Inviata il',
      },
      */
      //end
    ],
    [],
);

  const table = useMaterialReactTable({
    columns,
    data,
    localization:  MRT_Localization_IT,
    defaultColumn : { minSize: 20, maxSize: 1000, size: 180 }, //units are in px
    paginationDisplayMode: 'pages',
       initialState: { 
        showColumnFilters: true,
        columnVisibility: { _id: false } //hide _id column by default
       // pagination: { pageSize: 25, pageIndex: 2 }
    },
    enableColumnFilterModes: true,
    manualFiltering: true, //turn off built-in client-side filtering
    manualPagination: true, //turn off built-in client-side pagination
  //  manualSorting: true, //turn off built-in client-side sorting
    enableRowActions: true,
    muiToolbarAlertBannerProps: isError
      ? {
          color: 'error',
          children: 'Error loading data',
        }
      : undefined,
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    rowCount: meta?.totalRowCount ?? 0,
    positionActionsColumn: 'last',
    state: {
    columnFilters,
      globalFilter,
      isLoading,
      pagination,
      showAlertBanner: isError,
      showProgressBars: isRefetching,
      sorting,
    },

    renderDetailPanel: ({ row }) => <CiuseAdminSubRows row={row}/>,
        //custom expand button rotation
    muiExpandButtonProps: ({ row, table }) => ({
    children: row.getIsExpanded() ? <MinusIcon sx={{color:'red'}}/> : <AddIcon />,
    onClick: () => table.setExpanded({ [row.id]: !row.getIsExpanded() }), //only 1 detail panel open at a time
    sx: {
      transform: row.getIsExpanded() ? 'rotate(180deg)' : 'rotate(-90deg)',
      transition: 'transform 0.2s',
    },

}),

renderRowActions: ({ row }) => (
  <Box sx={{ display: 'flex', flexWrap: 'nowrap', gap: '8px' }}>
  <Tooltip title="Elimina pratica">
  <IconButton
    color="error"
    onClick={() => deletePratica(row)}
  >
   <DeleteIcon />
  </IconButton>
  </Tooltip>
  <Tooltip title="Assegna">
  <IconButton 
  color="secondary"
  onClick={() => handleAssegna(row.original)}>
  <SupervisorAccountIcon />
</IconButton>
</Tooltip>
</Box>
),
    

    renderTopToolbarCustomActions: () => (
        <Tooltip arrow title="Refresh Data">
          <IconButton onClick={() => refetch()}>
            <RefreshIcon />
          </IconButton>
        </Tooltip>
      ),
  });

  return <MaterialReactTable table={table} />;
};

const queryClient = new QueryClient();

const ChiuseAdminSub = () => (
  //App.tsx or AppProviders file. Don't just wrap this component with QueryClientProvider! Wrap your whole App!
  <QueryClientProvider client={queryClient}>
    <ChiuseAdmin />
  </QueryClientProvider>
);

export default ChiuseAdminSub;
